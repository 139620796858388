import { IconProps } from "./types";

const QuestionIcon: React.FC<IconProps> = (props) => {
	return (
		<svg
			width="19"
			height="19"
			viewBox="0 0 19 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.16667 0C4.10417 0 0 4.10417 0 9.16667C0 14.2292 4.10417 18.3333 9.16667 18.3333C14.2292 18.3333 18.3333 14.2292 18.3333 9.16667C18.3333 4.10417 14.2292 0 9.16667 0ZM8.24333 6.6125C8.435 6.235 8.57667 6.05583 8.69417 5.9625C8.7825 5.8925 8.9025 5.83333 9.16667 5.83333C9.6875 5.83333 10 6.225 10 6.64833C10 6.88 9.955 6.995 9.83167 7.14167C9.65917 7.34667 9.34 7.59583 8.70833 8.01333L8.33333 8.26V10C8.33333 10.221 8.42113 10.433 8.57741 10.5893C8.73369 10.7455 8.94565 10.8333 9.16667 10.8333C9.38768 10.8333 9.59964 10.7455 9.75592 10.5893C9.9122 10.433 10 10.221 10 10V9.1525C10.4517 8.84083 10.8308 8.5425 11.1058 8.21583C11.5033 7.74417 11.6667 7.24083 11.6667 6.64833C11.6667 5.42333 10.7225 4.16667 9.16667 4.16667C8.59667 4.16667 8.0925 4.31333 7.66 4.65583C7.25667 4.975 6.98167 5.41417 6.75667 5.86C6.70438 5.95789 6.67218 6.06524 6.66194 6.17574C6.6517 6.28624 6.66364 6.39768 6.69706 6.50351C6.73047 6.60934 6.78469 6.70742 6.85652 6.79201C6.92836 6.87661 7.01637 6.946 7.11538 6.99612C7.2144 7.04623 7.32243 7.07607 7.43313 7.08387C7.54383 7.09168 7.65498 7.07729 7.76004 7.04156C7.86511 7.00582 7.96198 6.94946 8.04498 6.87578C8.12797 6.80211 8.1954 6.71259 8.24333 6.6125ZM10 12.9167C10 12.6957 9.9122 12.4837 9.75592 12.3274C9.59964 12.1711 9.38768 12.0833 9.16667 12.0833C8.94565 12.0833 8.73369 12.1711 8.57741 12.3274C8.42113 12.4837 8.33333 12.6957 8.33333 12.9167V13.3333C8.33333 13.5543 8.42113 13.7663 8.57741 13.9226C8.73369 14.0789 8.94565 14.1667 9.16667 14.1667C9.38768 14.1667 9.59964 14.0789 9.75592 13.9226C9.9122 13.7663 10 13.5543 10 13.3333V12.9167Z"
				fill="currentColor"
				fillOpacity="0.5"
			/>
		</svg>
	);
};

export default QuestionIcon;
