import QuestionIcon from "@/components/icons/QuestionIcon";
import { useSlotsOnboarding } from "@/components/slots/onboarding/model";
import Coin from "@/components/ui/Coin";
import RectangleWrapper from "@/components/ui/RectangleWrapper";
import { useInView } from "react-intersection-observer";
import { characters } from "@/constants";
import { getMeClient, SlotsService } from "@/lib/actions";
import { cn, numberFormat } from "@/lib/utils";
import { useCurrentLocale } from "@/locales/client";
import { useQuery, useInfiniteQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { createFileRoute } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import ScreenLoader from "@/components/ui/ScreenLoader";
import Skeleton from "@/components/ui/Skeleton";

const LIMIT = 10;
let page = 0;

const SlotsProfilePage = () => {
	const { t } = useTranslation();
	const { ref, inView } = useInView();

	const { data: userData, isLoading: userDataLoading } = useQuery({
		queryKey: ["user"],
		queryFn: async () => {
			return await getMeClient();
		},
	});

	const { data: statsData, isLoading: statsDataLoading } = useQuery({
		queryKey: ["slots-stats"],
		queryFn: async () => {
			return await SlotsService.getUserStats();
		},
		staleTime: 1000 * 20,
	});

	const {
		data: gamesDataPages,
		fetchNextPage,
		hasNextPage,
		isLoading: gamesDataLoading,
	} = useInfiniteQuery({
		queryKey: ["games-stats"],
		queryFn: async ({ pageParam }) => {
			return await SlotsService.getUserGameHistory(LIMIT, pageParam * LIMIT);
		},
		staleTime: 1000 * 20,
		initialPageParam: 0,
		getNextPageParam: (lastPage) => {
			if (lastPage.payload.items.length === LIMIT) {
				page = page + 1;
				return page;
			}
			return;
		},
	});

	const gamesData = gamesDataPages
		? gamesDataPages.pages.reduce(
				(accumulator, currentValue) => [
					...accumulator,
					...currentValue.payload.items,
				],
				[],
		  )
		: [];
	const locale = useCurrentLocale();
	useEffect(() => {
		if (inView) {
			fetchNextPage();
		}
	}, [fetchNextPage, inView]);

	const { step, setOnboardingOnStep } = useSlotsOnboarding();

	return (
		<main className="h-screen flex flex-col gap-10 p-6 pb-[128px]">
			<div className="flex flex-col items-center gap-4">
				<h1 className="text-2xl flex items-center space-x-2 font-semibold text-center transition ease-in-out animate-in duration-300 slide-in-from-left-10 fade-in-0">
					<span>{t("slots.yourProfile")}</span>
					<button onClick={() => setOnboardingOnStep(7)}>
						<QuestionIcon />
					</button>
				</h1>
				<div className="flex flex-col items-center justify-center">
					{userDataLoading ? (
						<>
							<div className="w-[102px] h-[102px]">
								<Skeleton />
							</div>
							<div className="w-28 h-4 mt-2">
								<Skeleton />
							</div>
						</>
					) : (
						<>
							<img
								src={characters[userData?.level ?? 1].image}
								width={102}
								height={102}
								alt="avatar"
								className="object-cover"
							/>
							<p>{userData?.username ?? ""}</p>
						</>
					)}
				</div>
				<div
					className={cn(
						"flex items-center justify-between space-x-2 w-full relative",
						step === 7 ? "z-10" : "",
					)}
				>
					{/* Onboarding */}
					{step === 7 ? (
						<div className="z-10 absolute -top-20 left-1/2 -translate-x-1/2 text-center text-sm max-w-64 text-white/80 backdrop-blur rounded-md px-2 py-2">
							<p>{t("slots.onboarding7")}</p>
						</div>
					) : null}
					<RectangleWrapper className="flex flex-col text-center w-full px-4 py-2">
						<div className="text-white/50 text-sm">{t("slots.totalSpins")}</div>
						{statsDataLoading ? (
							<div className="w-10 h-6 mt-1 mx-auto">
								<Skeleton />
							</div>
						) : (
							<div className="text-lg">
								{statsData?.payload?.spin_count ?? 0}
							</div>
						)}
					</RectangleWrapper>
					<RectangleWrapper className="flex flex-col text-center w-full px-4 py-2">
						<div className="text-white/50 text-sm">{t("slots.totalWon")}</div>
						<div className="text-lg flex items-center justify-center space-x-1">
							{statsDataLoading ? (
								<div className="w-10 h-6 mt-1">
									<Skeleton />
								</div>
							) : (
								<span>{numberFormat(statsData?.payload?.total_win)}</span>
							)}
							<Coin className="w-5 h-5" />
						</div>
					</RectangleWrapper>
				</div>

				{step === 9 ? (
					<div className="z-40 absolute bottom-28 text-center left-1/2 -translate-x-1/2 text-sm max-w-64 text-white/80 backdrop-blur-md rounded-md px-2 py-2">
						<p>{t("slots.onboarding9")}</p>
					</div>
				) : null}

				<div className="w-full relative">
					<h3>{t("slots.gamesHistory")}</h3>

					{step === 8 ? (
						<div className="z-10 absolute -top-14 left-1/2 -translate-x-1/2 text-center text-sm max-w-64 text-white/80 backdrop-blur rounded-md px-2 py-2">
							<p>{t("slots.onboarding8")}</p>
						</div>
					) : null}

					<div
						className={cn(
							"mt-2 space-y-2 overflow-auto max-h-[200px] h-auto relative",
							step === 8 ? "z-10" : "",
						)}
					>
						{gamesDataLoading && !gamesData?.length
							? [1, 2, 3, 4, 5, 6, 7].map((item) => {
									return (
										<RectangleWrapper
											key={item}
											className="flex items-center justify-between px-4 py-2"
										>
											<div className="flex items-center space-x-2">
												<Coin className="w-8 h-8" />
												<div>
													<div className="w-24 h-4">
														<Skeleton />
													</div>
													<div className="w-12 h-5 mt-2">
														<Skeleton />
													</div>
												</div>
											</div>
											<div className="w-24 h-5">
												<Skeleton />
											</div>
										</RectangleWrapper>
									);
							  })
							: gamesData?.length
							? gamesData.map((game: any, index: number) => {
									const isPositiveGame = game.total_win >= 0;
									return (
										<RectangleWrapper
											key={index}
											className="flex items-center justify-between px-4 py-2"
										>
											<div className="flex items-center space-x-2">
												<Coin className="w-8 h-8" />
												<div>
													<p className="text-base font-semibold capitalize">
														{game.slot_name}
													</p>
													<p
														className={cn(
															"text-sm",
															isPositiveGame ? "text-green-1" : "text-red-1",
														)}
													>
														{isPositiveGame ? "+" : "-"}
														{numberFormat(Math.abs(game.total_win))}
													</p>
												</div>
											</div>
											<div className="text-white/50 text-sm font-medium">
												{Intl.DateTimeFormat(locale).format(
													new Date(game.last_spin_at),
												)}
											</div>
										</RectangleWrapper>
									);
							  })
							: t("slots.noData")}
						{!!hasNextPage && <div ref={ref}></div>}
					</div>
				</div>
			</div>
		</main>
	);
};

export const Route = createFileRoute("/slots/profile/")({
	component: SlotsProfilePage,
});
