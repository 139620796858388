import Coin from "@/components/ui/Coin";
import React, { useState } from "react";
import Balancer from "react-wrap-balancer";
import BuyCoinsSheet from "./BuyCoinsSheet";
import { useTranslation } from "react-i18next";
import { Link } from "@tanstack/react-router";

interface StarsTaskProps {
	minStars: number;
	minCoins: number;
}

const StarsTask = ({ minStars, minCoins }: StarsTaskProps) => {
	const { t } = useTranslation();

	const [openBuySheet, setOpenBuySheet] = useState(false);

	return (
		<>
			<Link
				href="javascript:void;"
				onClick={() => setOpenBuySheet(true)}
				className="relative w-full px-4 py-3 min-h-[118px] overflow-hidden text-black rounded-xl border border-[#DAB045] [background:radial-gradient(174.16%_99.64%_at_50%_100.02%,#DAB045_16.76%,#F8D621_100%)]"
			>
				<img
					src="/star_bg.png"
					alt=""
					className="absolute right-8 top-1/2 -translate-y-1/2 z-0 scale-150 opacity-60 brightness-200"
				/>
				<p className="text-lg leading-6 font-medium z-10 relative">
					<Balancer>{t("stars.support")}</Balancer>
				</p>
				<div className="flex items-center justify-between mt-1 z-10 relative">
					<div className="space-x-1 flex items-center">
						<Coin className="w-6 h-6" />
						<span className="text-base">
							{t("stars.from")} {Intl.NumberFormat("ru").format(minCoins)}
						</span>
					</div>
					<button
						onClick={() => setOpenBuySheet(true)}
						className="inline-flex items-center justify-center px-6 py-2 bg-white rounded-full space-x-1"
					>
						<p className="text-sm text-black font-medium leading-[18px]">
							{t("stars.from")} {minStars}
						</p>
						<img src="/star.png" alt="" className="w-4 h-4" />
					</button>
				</div>
			</Link>
			<BuyCoinsSheet open={openBuySheet} setOpen={setOpenBuySheet} />
		</>
	);
};

export default StarsTask;
