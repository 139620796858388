import Balancer from "react-wrap-balancer";

import { Sheet, SheetContent } from "@/components/ui/sheet";
import { useState } from "react";

interface ReferralModalProps {
	image?: string;
	title?: string;
	description?: string;
	show?: boolean;
	onClose?: () => void;
}

const ReferralModal: React.FC<ReferralModalProps> = ({
	image,
	title,
	description,
	show,
	onClose,
}) => {
	const [open, setOpen] = useState(true);

	const handleClose = (open: boolean) => {
		setOpen(open);
		if (!open) {
			onClose && onClose();
		}
	};

	if (!show) {
		return null;
	}

	return (
		<Sheet open={open} onOpenChange={handleClose}>
			<SheetContent
				side="center"
				layer="second"
				className="flex flex-col items-center pt-[6vh] px-6 pb-[10vh] data-[state=open]:duration-[0s] w-[92%] max-w-[342px] !z-[60]"
			>
				<img
					src={`${import.meta.env.VITE_PUBLIC_BASE_URL}/${image}`}
					alt=""
					width={64}
					height={64}
				/>
				<h1 className="text-2xl font-semibold text-center">
					<Balancer>{title}</Balancer>
				</h1>

				<p className="text-center">
					<Balancer>{description}</Balancer>
				</p>
			</SheetContent>
		</Sheet>
	);
};

export default ReferralModal;
