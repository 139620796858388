import { cn } from "@/lib/utils";

interface EmojiProps {
	src: string;
	className?: string;
}

const Emoji: React.FC<EmojiProps> = ({ src, className }) => {
	return (
		<img
			src={src}
			alt="emoji"
			width={360}
			height={360}
			className={cn("w-8 h-8", className)}
		/>
	);
};

export default Emoji;
