import i18n from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
import en from './en';
import ru from './ru';

const resources = {
  en: {
    translation: en,
  },
  ru: {
    translation: ru,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'en',
  debug: import.meta.env.VITE_PUBLIC_DEBUG === 'true',

  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

export const useI18n = (section: string) => {
  const { t } = useTranslation();

  return (key: string) => {
    return t(`${section}.${key}`);
  };
};

export const useCurrentLocale = () => i18n.language;

export default i18n;
