import Coin from "@/components/ui/Coin";

import { LevelType } from "@/types";

import { characters } from "@/constants";

const Friend = ({
	username,
	reward,
	level,
}: { username: string; reward: number; level: LevelType }) => {
	return (
		<div className="flex items-center gap-2">
			<div className="w-10 h-10 rounded-full relative bg-black">
				<img
					src={characters[level].image}
					alt="avatar"
					className="absolute object-cover"
				/>
			</div>

			<p>{username}</p>

			<div className="flex items-center gap-2 ml-auto">
				<p className="font-semibold">{`+${reward.toLocaleString("en-US")}`}</p>

				<Coin className="w-6 h-6" />
			</div>
		</div>
	);
};

export default Friend;
