import { useState } from "react";
import toast from "react-hot-toast";

import { cn } from "@/lib/utils";
import { logger } from "@/lib/logger";
import { spinWheel } from "@/lib/actions";
import { useI18n } from "@/locales/client";
import { useQueryClient } from "@tanstack/react-query";

import Coin from "@/components/ui/Coin";
import Button from "@/components/ui/Button";
import WheelArrow from "@/components/icons/wheel/WheelArrow";
import WheelCircle from "@/components/icons/wheel/WheelCircle";
import WheelSegments from "@/components/icons/wheel/WheelSegments";

interface WheelClientProps {
	lastWheelSpin: EpochTimeStamp;
}

const WheelClient: React.FC<WheelClientProps> = ({ lastWheelSpin }) => {
	const t = useI18n("wheel");

	const [spin, setSpin] = useState(0);
	const [loading, setLoading] = useState(false);

	const expiryTimestamp = new Date(lastWheelSpin);

	expiryTimestamp.setDate(expiryTimestamp.getDate() + 1);

	const queryClient = useQueryClient();

	const onSpin = async () => {
		try {
			setLoading(true);

			const reward = await spinWheel();

			const number = {
				100000: 45,
				10000: 90,
				500: 135,
				5000: 180,
				1000: 225,
				25000: 270,
				50000: 315,
			}[reward];

			const degree = -number - 1800;

			setSpin(degree);
			await queryClient.invalidateQueries({
				queryKey: ["user"],
			});

			setTimeout(async () => {
				toast.success(`+${reward}`);
			}, 5000);
		} catch (error) {
			logger.error(error);

			toast.error("Error");
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<div className="w-[80vw] aspect-square rounded-full [box-shadow:0px_0px_92.48px_27.74px_#071160] relative">
				<div
					className={cn(
						"w-full aspect-square rounded-full absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%] z-0",
						"[background:linear-gradient(225deg,#000604_16%,#303030_44.73%,#000604_87.83%)]",
					)}
				/>

				<div
					className={cn(
						"w-[86.3%] aspect-square rounded-full absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%] z-[1]",
						"[background:radial-gradient(50%_50%_at_50%_50%,#08146E_57.69%,#01011A_100%)]",
					)}
				/>

				<div
					className={cn(
						"w-[86.3%] aspect-square rounded-full absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%] mix-blend-multiply z-[2]",
						"[background:radial-gradient(50%_50%_at_50%_50%,#FFFFFF_81%,#FCFCFD_87%,#F3F4F6_91%,#E9ECF0_94%,#B9C3CE_97%,#899BAD_100%)]",
					)}
				/>

				<div
					style={{
						transform: `translateX(-50%) translateY(-50%) rotate(${spin}deg)`,
						transition: "transform 5s ease-in-out",
					}}
					className="absolute left-1/2 top-1/2 z-[3] overflow-hidden"
				>
					<div className="relative">
						<div className="flex flex-col items-center gap-1 absolute top-4 left-1/2 translate-x-[-50%]">
							<Coin className="w-8 h-8" />

							<p className="text-[13px] text-black font-medium leading-[18px]">
								{t("bonus")}
							</p>
						</div>

						<WheelSegments />
					</div>
				</div>

				<WheelArrow className="absolute left-1/2 top-0 translate-x-[-50%] z-[4]" />

				<WheelCircle className="absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%] z-[5]" />
			</div>

			<Button
				onClick={onSpin}
				disabled={loading || Date.now() - expiryTimestamp.getTime() < 0}
				className="transition ease-in-out animate-in duration-300 slide-in-from-bottom fade-in-0"
			>
				{t("spin")}
			</Button>
		</>
	);
};

export default WheelClient;
