import axios from 'axios';

import { logger } from '@/lib/logger';
import { createJwt, refreshJwt } from './actions';
import { LocalStorageService } from '@/services/localStorage.service';

export const api = axios.create({
  baseURL: import.meta.env.VITE_PUBLIC_API,
});

api.interceptors.request.use(
  async (config) => {
    const accessToken = LocalStorageService.get('access_token');

    logger.debug('ACCESS TOKEN', accessToken);

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    logger.error(error);
  },
  { synchronous: false }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    let refresh = LocalStorageService.get('refresh_token');
    if (error.response?.status === 403 && !originalRequest._retry && refresh) {
      originalRequest._retry = true;
      const result = await refreshJwt({ refresh });
      const expires = new Date();
      expires.setMonth(expires.getMonth() + 1);
      LocalStorageService.set('access_token', result.access);
      LocalStorageService.set('refresh_token', result.refresh);
      axios.defaults.headers.common['Authorization'] =
        `Bearer ${result.access}`;
      return api(originalRequest);
    } else if (error.response?.status === 401) {
      const result = await createJwt({
        initData: window.Telegram.WebApp.initData,
        referral_code: window.Telegram.WebApp?.initDataUnsafe?.start_param,
      });
      LocalStorageService.set('access_token', result.access);
      LocalStorageService.set('refresh_token', result.refresh);
      return api(originalRequest);
    } else {
      LocalStorageService.remove('access_token');
      LocalStorageService.remove('refresh_token');
    }
    return Promise.reject(error);
  }
);

export const withoutToken = axios.create({
  baseURL: import.meta.env.VITE_PUBLIC_API,
});

export const slotsApi = axios.create({
  baseURL: import.meta.env.VITE_PUBLIC_SLOTS_API,
});

slotsApi.interceptors.request.use(
  async (config) => {
    const accessToken = LocalStorageService.get('access_token');

    logger.debug('ACCESS TOKEN', accessToken);

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    logger.error(error);
  },
  { synchronous: false }
);
