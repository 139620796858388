import { bot_handle } from '@/constants'
import { createFileRoute } from '@tanstack/react-router'

const DesktopPage = () => {
  return (
    <main className="min-h-screen flex flex-col items-center gap-10 pt-24">
      <div className="flex flex-col items-center gap-6 font-semibold">
        <h1 className="text-[28px] leading-8">Play on your phone</h1>

        <p className="text-lg leading-6">{`@${bot_handle}`}</p>
      </div>

      <img
        src="/qr.png"
        alt="qr"
        width={360}
        height={360}
        className="w-60 h-60 rounded-2xl"
      />
    </main>
  )
}

export const Route = createFileRoute('/desktop/')({
  component: DesktopPage,
})
