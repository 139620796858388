import { useEffect, useState } from "react";
import useTelegram from "./useTelegram";
import { createJwt } from "@/lib/actions";
import { LocalStorageService } from "@/services/localStorage.service";

const useCreateJwt = () => {
	const [sent, setSent] = useState(false);
	const [isReady, setReady] = useState(false);

	const telegram = useTelegram();

	useEffect(() => {
		(async () => {
			if (telegram && !sent) {
				try {
					setSent(true);

					const refCode = telegram.initDataUnsafe?.start_param;
					const result = await createJwt({
						initData: telegram!.initData,
						referral_code: refCode ?? null,
					});

					// @todo use TokenService.setTokens(access, refresh)
					LocalStorageService.set("access_token", result.access);
					LocalStorageService.set("refresh_token", result.refresh);

					setReady(true);
				} catch (error) {
					console.log(error);

					alert(error);
				}
			}
		})();
	}, [telegram]);

	return isReady;
};

export default useCreateJwt;
