import { useI18n } from "@/locales/client";
import { getFriends, getMeClient } from "@/lib/actions";

import Coin from "@/components/ui/Coin";

import Friend from "../../components/friends/Friend";
import InviteButton from "../../components/friends/InviteButton";

import { bot_handle } from "@/constants";
import { useQuery } from "@tanstack/react-query";
import ScreenLoader from "@/components/ui/ScreenLoader";
import { createFileRoute } from "@tanstack/react-router";
import { Balancer } from "react-wrap-balancer";
import StarIcon from "@/components/icons/StarIcon";

const FriendsPage = () => {
	const t = useI18n("friends");

	const { data: friends } = useQuery({
		queryKey: ["friends"],
		queryFn: async () => await getFriends(),
	});

	const { data: user } = useQuery({
		queryKey: ["user"],
		queryFn: async () => await getMeClient(),
	});

	if (!user || !t || !friends) {
		return <ScreenLoader />;
	}

	const shareUrl = `https://t.me/share/url?url=https://t.me/${bot_handle}/app?startapp=${user.id}&text=🏆+2k Ocean Coin as a first-time bonus💎`;

	return (
		<main className="h-screen flex flex-col gap-10 p-6 pb-[128px]">
			<div className="flex flex-col gap-4">
				<h1 className="text-2xl font-semibold text-center transition ease-in-out animate-in duration-300 slide-in-from-left-10 fade-in-0">
					{t("inviteFriendsAnd")}
				</h1>

				<div className="overflow-y-auto">
					<div className="flex items-center px-8 py-4 gap-[35px] border border-blue-500 rounded-xl bg-gradient-to-b from-blue-600 to-blue-500 self-stretch">
						<Coin className="w-10 h-10" />

						<div className="flex flex-col gap-1 text-[13px] leading-[18px] font-medium">
							<p>{`${t("directRef")}: +30,000`}</p>

							<p>{`${t("refRef")}: +5,000`}</p>

							<p>{`${t("eachNext")}: +1000`}</p>
						</div>
					</div>

					<div className="mt-4 flex overflow-hidden items-center px-8 py-3 gap-[35px] rounded-xl self-stretch  min-h-[86px] text-black border border-[#DAB045] [background:radial-gradient(174.16%_99.64%_at_50%_100.02%,#DAB045_16.76%,#F8D621_100%)]">
						<div className="relative">
							<StarIcon className="z-10 relative" />
							<img
								src="/star_bg.png"
								alt=""
								className="absolute left-0 top-1/2 -translate-y-1/2 z-0 opacity-30 brightness-200 scale-[3]"
							/>
						</div>
						<div className="flex flex-col gap-1 space-y-0.5">
							<p className="text-blue-500 text-xl leading-6 font-semibold">
								<Balancer>{t("stars")}</Balancer>
							</p>
							<p className="text-[13px] leading-[18px]">
								<Balancer>{t("starsRef")}</Balancer>
							</p>
						</div>
					</div>

					{[1].length > 0 && (
						<div className="flex flex-col gap-4 mt-4">
							<h2 className="text-lg font-semibold leading-6">{`${friends.length} friends`}</h2>

							<div className="flex flex-col gap-4 overflow-y-auto max-h-[30vh]">
								{[
									{
										invited: { username: "keka", level: 2 as any },
										reward: 30000,
									},
									{
										invited: { username: "keka", level: 2 as any },
										reward: 30000,
									},
									{
										invited: { username: "keka", level: 2 as any },
										reward: 30000,
									},
									{
										invited: { username: "keka", level: 2 as any },
										reward: 30000,
									},
									{
										invited: { username: "keka", level: 2 as any },
										reward: 30000,
									},
									{
										invited: { username: "keka", level: 2 as any },
										reward: 30000,
									},
									{
										invited: { username: "keka", level: 2 as any },
										reward: 30000,
									},
								].map((friend, index) => (
									<Friend
										key={index}
										username={friend.invited.username}
										reward={friend.reward}
										level={friend.invited.level}
									/>
								))}
							</div>
						</div>
					)}
				</div>
			</div>

			<InviteButton shareUrl={shareUrl} />
		</main>
	);
};

export const Route = createFileRoute("/friends/")({
	component: FriendsPage,
});
