import { cn } from "@/lib/utils";
import React from "react";

interface RectangleWrapperProps extends React.PropsWithChildren {
	className?: string;
}

const RectangleWrapper = ({ children, className }: RectangleWrapperProps) => {
	return (
		<div
			className={cn(
				"[background:linear-gradient(0deg,rgba(0,0,0,0)_0%,#151515_100%)] rounded-xl border border-gray-300",
				className,
			)}
		>
			{children}
		</div>
	);
};

export default RectangleWrapper;
