import { twMerge } from "tailwind-merge";
import { type ClassValue, clsx } from "clsx";

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export function numberFormat(number?: number) {
	if (!number) {
		return "0 K";
	}

	if (number >= 1000000000) {
		return `${
			number % 1000000000 === 0
				? number / 1000000000
				: (number / 1000000000).toFixed(1)
		} B`;
	} else if (number >= 1000000) {
		return `${
			number % 1000000 === 0 ? number / 1000000 : (number / 1000000).toFixed(1)
		} M`;
	} else if (number >= 1000) {
		return `${
			number % 1000 === 0 ? number / 1000 : (number / 1000).toFixed(1)
		} K`;
	} else {
		return `${number.toFixed(0)}`;
	}
}

export function formatSeconds(sec_num: number) {
	let hours: number | string = Math.floor(sec_num / 3600);
	let minutes: number | string = Math.floor((sec_num - hours * 3600) / 60);
	let seconds: number | string = Math.floor(
		sec_num - hours * 3600 - minutes * 60,
	);

	if (hours < 10) {
		hours = `0${hours}`;
	}
	if (minutes < 10) {
		minutes = `0${minutes}`;
	}
	if (seconds < 10) {
		seconds = `0${seconds}`;
	}
	return `${hours}:${minutes}:${seconds}`;
}

export async function sleep(ms: number) {
	await _sleep(ms);
}

function _sleep(ms: number) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}
