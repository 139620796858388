"use client";

import { isToday } from "date-fns";
import toast from "react-hot-toast";
import { UTCDate } from "@date-fns/utc";
import { useEffect, useState } from "react";

import { cn } from "@/lib/utils";
import { completeQuest } from "@/lib/actions";
import useTelegram from "@/hooks/useTelegram";
import { useI18n } from "@/locales/client";

import Coin from "@/components/ui/Coin";
import HourglassIcon from "@/components/icons/HourglassIcon";
import CheckCircleIcon from "@/components/icons/CheckCircleIcon";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";

import { DailyRewardType } from "@/types";
import { useQueryClient } from "@tanstack/react-query";

interface DailySheetProps {
	daily_rewards: DailyRewardType[];
	lastClaimDate: EpochTimeStamp;
}

const DailySheet: React.FC<DailySheetProps> = ({
	daily_rewards,
	lastClaimDate,
}) => {
	const t = useI18n("earn");

	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);

	const telegram = useTelegram();

	const nonClaimedExist = daily_rewards.some(
		(daily_reward) => !daily_reward.claimed,
	);

	const nonClaimedReward = nonClaimedExist
		? daily_rewards.find((daily_reward) => !daily_reward.claimed)
		: daily_rewards[daily_rewards.length - 2];

	const todayReward = isToday(new UTCDate(lastClaimDate))
		? daily_rewards
				.slice()
				.reverse()
				.find((daily_reward) => daily_reward.claimed)
		: nonClaimedReward;

	useEffect(() => {
		telegram?.BackButton.onClick(() => setOpen(false));

		if (open) {
			telegram?.BackButton.show();
		} else {
			telegram?.BackButton.hide();
		}

		return () => {
			telegram?.BackButton.offClick(() => setOpen(false));
		};
	}, [open]);

	const queryClient = useQueryClient();

	const onClaim = async () => {
		try {
			setLoading(true);

			await completeQuest("daily");

			if (todayReward) {
				await queryClient.invalidateQueries({ queryKey: ["daily-rewards"] });
				await queryClient.invalidateQueries({ queryKey: ["user"] });
				toast.success(`+${todayReward.reward}`);
			}
		} catch (error) {
			console.error(error);
			await queryClient.invalidateQueries({ queryKey: ["daily-rewards"] });
			await queryClient.invalidateQueries({ queryKey: ["user"] });
			toast.error("Error");
		} finally {
			setLoading(false);
		}
	};

	const black = todayReward?.claimed || loading;

	return (
		<Sheet open={open} onOpenChange={setOpen}>
			<SheetTrigger asChild>
				<div
					className={cn(
						"flex items-center justify-between px-4 py-[15px] border rounded-xl transition-colors duration-300 ease-in-out bg-gradient-to-b self-stretch",
						black
							? "border-gray-300 from-gray-500 to-black"
							: "border-blue-500 from-blue-600 to-blue-500",
					)}
				>
					<div className="flex flex-col gap-1">
						<p>{`${t("day")} ${todayReward?.day}`}</p>

						<div className="flex items-center gap-1">
							<Coin />

							<p className="text-[13px] font-medium leading-[18px]">{`+${todayReward?.reward}`}</p>
						</div>
					</div>

					{loading ? (
						<HourglassIcon className="w-6 h-6 text-gray-100" />
					) : todayReward?.claimed ? (
						<CheckCircleIcon className="w-6 h-6 text-positive" />
					) : (
						<button
							onClick={(e) => {
								e.stopPropagation();

								onClaim();
							}}
							className="inline-flex items-center justify-cente px-6 py-2 bg-white rounded-full"
						>
							<p className="text-sm text-black font-medium leading-[18px]">
								{t("claim")}
							</p>
						</button>
					)}
				</div>
			</SheetTrigger>

			<SheetContent
				side="bottom"
				className="flex flex-col p-6 gap-6 max-h-full"
			>
				<h1 className="text-2xl font-semibold">Daily reward</h1>

				<div className="overflow-auto">
					<ul className="flex flex-col gap-1.5">
						{daily_rewards.map((daily_reward, index) => (
							<RewardItem {...daily_reward} key={index} />
						))}
					</ul>
				</div>
			</SheetContent>
		</Sheet>
	);
};

const RewardItem = ({
	day,
	claimed,
	reward,
}: { day: number; claimed: boolean; reward: number }) => {
	return (
		<li
			className={cn(
				"flex items-center justify-between px-4 py-[15px] rounded-xl border border-gray-300 transition-colors duration-300 ease-in-out",
				claimed
					? "border-blue-200 bg-blue-300"
					: "bg-gradient-to-b from-gray-500 to-black",
			)}
		>
			<p className="text-[13px] font-medium leading-[18px]">{`Day ${day}`}</p>

			<div className="flex items-center gap-4">
				<div className="flex items-center gap-1">
					<Coin />

					<p className="text-[13px] font-medium leading-[18px]">{`+${reward}`}</p>
				</div>

				{claimed && (
					<CheckCircleIcon className="w-[18px] h-[18px] text-positive" />
				)}
			</div>
		</li>
	);
};

export default DailySheet;
