"use client";

import { getMeClient } from "@/lib/actions";

import Title from "../../components/mine/Title";
import MineClient from "../../components/mine/client";
import WheelBanner from "../../components/mine/WheelBanner";
import { useQuery } from "@tanstack/react-query";
import ScreenLoader from "@/components/ui/ScreenLoader";
import { createFileRoute, Link } from "@tanstack/react-router";
import BannerCarousel from "@/components/ui/BannerCarousel";
import { useTranslation } from "react-i18next";

const SlotsBanner = () => {
	const { t } = useTranslation();
	return (
		<div>
			<Link to="/slots" className="relative ">
				<div className="border border-[#717BC6] rounded-xl [background:radial-gradient(81.54%_50%_at_50%_50%,#0F20A2_41.37%,#071160_100%)] h-[170px]">
					<p className="text-[#E7E8F5] text-2xl font-semibold absolute left-4 top-4">
						{t("mine.bannerTest")}
						<br />
						{t("mine.bannerFortune")}
						<br />
						{t("mine.bannerCasino")}
					</p>
					<div className="h-full w-full flex items-center">
						<img src="/box.png" alt="" width="346" height="144" className="" />
					</div>
					<div className="rounded-full bg-white text-black font-medium text-[13px] w-fit px-4 py-2 absolute right-4 bottom-4">
						{t("mine.playNow")}
					</div>
				</div>
			</Link>
		</div>
	);
};

const MinePage = () => {
	const { data: user, isLoading } = useQuery({
		queryKey: ["user"],
		queryFn: async () => await getMeClient(),
	});

	if (isLoading) {
		return <ScreenLoader />;
	}

	if (!user) {
		return <main>NO USER</main>;
	}

	const banners = [
		<SlotsBanner />,
		<WheelBanner
			lastWheelSpin={user!.last_wheel_of_fortune_dt ?? Date.now()}
		/>,
	];

	return (
		<main className="min-h-screen flex flex-col items-center pb-[128px] gap-6">
			<div className="p-6">
				<Title initialData={user} />
			</div>
			<div className="relative w-full">
				<BannerCarousel banners={banners} />
			</div>
			<div className="px-6">
				<MineClient />
			</div>
		</main>
	);
};

export const Route = createFileRoute("/mine/")({
	component: MinePage,
});
