import MoneyDown from "@/components/icons/MoneyDown";
import QuestionIcon from "@/components/icons/QuestionIcon";
import { useSlotsOnboarding } from "@/components/slots/onboarding/model";
import Coin from "@/components/ui/Coin";
import RectangleWrapper from "@/components/ui/RectangleWrapper";
import ScreenLoader from "@/components/ui/ScreenLoader";
import Skeleton from "@/components/ui/Skeleton";
import { characters } from "@/constants";
import { getMeClient, SlotsService } from "@/lib/actions";
import { cn, numberFormat } from "@/lib/utils";
import { useCurrentLocale } from "@/locales/client";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { createFileRoute, Link } from "@tanstack/react-router";

import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";

const LIMIT = 10;

let page = 0;

const SlotsWalletPage = () => {
	const { t } = useTranslation();
	const { ref, inView } = useInView();
	const { data: userData, isLoading: userLoading } = useQuery({
		queryKey: ["user"],
		queryFn: async () => {
			return await getMeClient();
		},
	});

	const { data, isLoading: balanceLoading } = useQuery({
		queryKey: ["balance"],
		queryFn: async () => {
			return await SlotsService.getBalance();
		},
		staleTime: 1000 * 20,
	});

	const {
		data: transactionsDataPages,
		fetchNextPage,
		hasNextPage,
		isLoading: transactionsDataLoading,
	} = useInfiniteQuery({
		queryKey: ["transactions-history"],
		queryFn: async ({ pageParam }) => {
			return await SlotsService.getTransactionsHistory(
				LIMIT,
				pageParam * LIMIT,
			);
		},
		staleTime: 1000 * 20,
		initialPageParam: 0,
		getNextPageParam: (lastPage) => {
			if (lastPage.payload.items.length === LIMIT) {
				page = page + 1;
				return page;
			}
			return;
		},
	});

	const transactionsData = transactionsDataPages
		? transactionsDataPages.pages.reduce(
				(accumulator, currentValue) => [
					...accumulator,
					...currentValue.payload.items,
				],
				[],
		  )
		: [];

	const locale = useCurrentLocale();

	useEffect(() => {
		if (inView) {
			fetchNextPage();
		}
	}, [fetchNextPage, inView]);

	const { step, setOnboardingOnStep } = useSlotsOnboarding();

	const balance = data?.payload?.current_balance;

	return (
		<main className="h-screen flex flex-col gap-10 p-6 pb-[128px]">
			<div className="flex flex-col items-center gap-4">
				{userLoading ? (
					<div className="flex items-center justify-center space-x-2">
						<div className="w-[43px] h-[43px]">
							<Skeleton />
						</div>
						<div className="ml-2 w-24 h-4">
							<Skeleton />
						</div>
					</div>
				) : (
					<div className="flex items-center justify-center space-x-2">
						<img
							src={characters[userData?.level ?? 1].image}
							width={43}
							height={43}
							alt="avatar"
							className="object-cover"
						/>
						<p>{userData?.username}</p>
					</div>
				)}
				<h1 className="text-2xl flex items-center space-x-2 font-semibold text-center transition ease-in-out animate-in duration-300 slide-in-from-left-10 fade-in-0">
					<span>{t("slots.casinoWallet")}</span>
					<button onClick={() => setOnboardingOnStep(10)}>
						<QuestionIcon />
					</button>
				</h1>
				<div className={cn("flex flex-col items-center text-center relative")}>
					<div className={cn("relative", step === 10 ? "z-10" : "")}>
						<p>{t("slots.balance")}</p>
						<div className="flex items-center space-x-2">
							{balanceLoading ? (
								<div className="w-20 h-10">
									<Skeleton />
								</div>
							) : (
								<span className="text-4xl font-semibold">
									{numberFormat(balance)}
								</span>
							)}
							<Coin className="w-12 h-12" />
						</div>

						{step === 10 ? (
							<div className="z-10 absolute -bottom-32 w-full text-center left-1/2 -translate-x-1/2 text-sm text-white/80 backdrop-blur rounded-md px-2 py-2">
								<p>{t("slots.onboarding10")}</p>
							</div>
						) : null}
					</div>
					<div
						className={cn(
							"flex items-center mt-4 relative",
							step === 11 ? "z-10" : "",
						)}
					>
						<Link
							to="/slots/wallet/deposit"
							className="flex flex-col items-center space-y-1"
						>
							<div className="rounded-full bg-white w-[50px] h-[50px] flex items-center justify-center">
								<MoneyDown className="text-black" />
							</div>
							<span>{t("slots.topUp")}</span>
						</Link>
						<Link
							to="/slots/wallet/withdraw"
							className="flex flex-col items-center space-y-1 ml-8"
						>
							<div className="rounded-full bg-white w-[50px] h-[50px] flex items-center justify-center">
								<MoneyDown className="text-black rotate-180" />
							</div>
							<span>{t("slots.withdraw")}</span>
						</Link>

						{step === 11 ? (
							<div className="z-10 absolute -bottom-24 w-full text-center left-1/2 -translate-x-1/2 text-sm text-white/80 backdrop-blur rounded-md px-2 py-2">
								<p>{t("slots.onboarding11")}</p>
							</div>
						) : null}
					</div>
				</div>

				{step === 13 ? (
					<div className="z-40 absolute bottom-28 text-center left-1/2 -translate-x-1/2 text-sm max-w-64 text-white/80 backdrop-blur rounded-md px-2 py-2">
						<p>{t("slots.onboarding13")}</p>
					</div>
				) : null}
				{step === 14 ? (
					<div className="z-40 absolute bottom-28 text-center left-1/2 -translate-x-1/2 text-sm w-full px-16 text-white/80 backdrop-blur rounded-md py-2">
						<p>
							{t("slots.onboarding14")}
							<br /> {t("slots.onboarding15")}
						</p>
					</div>
				) : null}

				<div className="w-full mt-4 relative">
					{step === 12 ? (
						<div className="z-10 absolute -top-20 text-center left-1/2 -translate-x-1/2 text-sm max-w-96 text-white/80 backdrop-blur rounded-md py-2">
							<p>{t("slots.onboarding12")}</p>
						</div>
					) : null}

					<h3>{t("slots.transactionHistory")}</h3>
					<div
						className={cn(
							"mt-2 space-y-2 overflow-auto max-h-[250px] h-auto relative",
							step === 12 ? "z-10" : "",
						)}
					>
						{transactionsDataLoading && !transactionsData.length
							? [1, 2, 3, 4, 5, 6, 7].map((item) => {
									return (
										<RectangleWrapper
											key={item}
											className="flex items-center justify-between px-4 py-2"
										>
											<div className="flex items-center space-x-2">
												<Coin className="w-8 h-8" />
												<div>
													<div className="w-24 h-4">
														<Skeleton />
													</div>
													<div className="w-12 h-5 mt-2">
														<Skeleton />
													</div>
												</div>
											</div>
											<div className="w-24 h-5">
												<Skeleton />
											</div>
										</RectangleWrapper>
									);
							  })
							: transactionsData.length
							? transactionsData.map((transaction: any, index: number) => {
									const isPositiveGame = transaction.change >= 0;
									return (
										<RectangleWrapper
											key={index}
											className="flex items-center justify-between px-4 py-2"
										>
											<div className="flex items-center space-x-2">
												<Coin className="w-8 h-8" />
												<div>
													<p className="text-base font-semibold capitalize">
														{transaction.reason}
													</p>
													<p
														className={cn(
															"text-sm",
															isPositiveGame ? "text-green-1" : "text-red-1",
														)}
													>
														{isPositiveGame ? "+" : "-"}
														{numberFormat(Math.abs(transaction.change))}
													</p>
												</div>
											</div>
											<div className="text-white/50 text-sm font-medium">
												{Intl.DateTimeFormat(locale).format(
													new Date(transaction.created_at),
												)}
											</div>
										</RectangleWrapper>
									);
							  })
							: t("slots.noData")}
						{!!hasNextPage && <div ref={ref}></div>}
					</div>
				</div>
			</div>
		</main>
	);
};

export const Route = createFileRoute("/slots/wallet/")({
	component: SlotsWalletPage,
});
