import useTelegram from "@/hooks/useTelegram";

import { cn } from "@/lib/utils";
import { NavRoute } from "@/components/navbar/AppNavbar";
import { Link } from "@tanstack/react-router";

interface NavProps {
	routes: NavRoute[];
}

const Nav = ({ routes }: NavProps) => {
	const telegram = useTelegram();

	return (
		<nav
			className={cn(
				"flex items-center px-4 pt-6 pb-8 bg-black/80 w-full fixed bottom-0 left-0 z-30",
				"backdrop-blur-[20px] [-webkit-backdrop-filter:blur(20px)]",
			)}
		>
			{routes.map((route, index) => (
				<NavItem
					key={index}
					onClick={() => {
						telegram?.HapticFeedback.impactOccurred("soft");
						route.onSuccess?.();
					}}
					{...route}
				/>
			))}
		</nav>
	);
};

const NavItem = ({
	label,
	href,
	icon,
	active,
	onClick,
}: {
	label: string;
	href: string;
	icon: React.ReactNode;
	active: boolean;
	onClick?: () => void;
}) => {
	return (
		<Link
			to={href}
			href={href}
			onClick={onClick}
			className={cn(
				"flex flex-col items-center gap-2 flex-1 text-xs text-gray-100 leading-4 transition-colors duration-150 ease-in-out relative",
				active && "text-white",
			)}
		>
			{icon}

			<p>{label}</p>
		</Link>
	);
};

export default Nav;
