import { useEffect } from "react";
import { isAndroid, isIOS } from "react-device-detect";
import { useLocation, redirect, Navigate } from "@tanstack/react-router";

const PlatformProvider = () => {
	const pathname = useLocation({
		select: (location) => location.pathname,
	});

	if (
		!isAndroid &&
		!isIOS &&
		!pathname.includes("desktop") &&
		import.meta.env.VITE_PUBLIC_DEBUG !== "true"
	) {
		return <Navigate to="/desktop" />;
	}

	return null;
};

export default PlatformProvider;
