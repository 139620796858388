export const ref_reward = 5000;

export const ref_reward_premium = 25000;

export const bot_handle = 'ocean_kombat_bot';

export const characters = {
  1: {
    image: '/chars/shrimp.png',
    blur: '/chars/blur/shrimp.png',
    name: 'Shrimp',
    coins: '0K+',
    perClick: 1,
    energyLimit: 500,
  },
  2: {
    image: '/chars/seahorse.png',
    blur: '/chars/blur/seahorse.png',
    name: 'Seahorse',
    coins: '5K+',
    perClick: 2,
    energyLimit: 600,
  },
  3: {
    image: '/chars/crab.png',
    blur: '/chars/blur/crab.png',
    name: 'Crab',
    coins: '25K+',
    perClick: 4,
    energyLimit: 800,
  },
  4: {
    image: '/chars/clown.png',
    blur: '/chars/blur/clown.png',
    name: 'Clownfish',
    coins: '100K+',
    perClick: 6,
    energyLimit: 1200,
  },
  5: {
    image: '/chars/hedgehog.png',
    blur: '/chars/blur/hedgehog.png',
    name: 'Hedgehog fish',
    coins: '500K+',
    perClick: 8,
    energyLimit: 1800,
  },
  6: {
    image: '/chars/turtle.png',
    blur: '/chars/blur/turtle.png',
    name: 'Turtle',
    coins: '2M+',
    perClick: 10,
    energyLimit: 2500,
  },
  7: {
    image: '/chars/octopus.png',
    blur: '/chars/blur/octopus.png',
    name: 'Octopus',
    coins: '10M+',
    perClick: 12,
    energyLimit: 3500,
  },
  8: {
    image: '/chars/dolphin.png',
    blur: '/chars/blur/dolphin.png',
    name: 'Dolphin',
    coins: '40M+',
    perClick: 14,
    energyLimit: 5000,
  },
  9: {
    image: '/chars/shark.png',
    blur: '/chars/blur/shark.png',
    name: 'Shark',
    coins: '200M+',
    perClick: 16,
    energyLimit: 7000,
  },
  10: {
    image: '/chars/whale.png',
    blur: '/chars/blur/whale.png',
    name: 'Whale',
    coins: '1B+',
    perClick: 18,
    energyLimit: 10000,
  },
  11: {
    image: '/chars/orca.png',
    blur: '/chars/blur/shark.png',
    name: 'Orca',
    coins: '10B+',
  },
  12: {
    image: '/chars/leviathan.png',
    blur: '/chars/blur/shark.png',
    name: 'Leviathan',
    coins: '40B+',
  },
  13: {
    image: '/chars/megalodon.png',
    blur: '/chars/blur/shark.png',
    name: 'Megalodon',
    coins: '60B+',
  },
  14: {
    image: '/chars/kraken.png',
    blur: '/chars/blur/shark.png',
    name: 'Kraken',
    coins: '100B+',
  },
};

export const LEVEL_BY_COST: Record<number, number> = {
  1: 5_000,
  2: 25_000,
  3: 100_000,
  4: 500_000,
  5: 2_000_000,
  6: 10_000_000,
  7: 40_000_000,
  8: 200_000_000,
  9: 1_000_000_000,
};
