import IconoirWalletSolid from "@/components/icons/IconoirWalletSolid";
import QuestionIcon from "@/components/icons/QuestionIcon";
import { useSlotsOnboarding } from "@/components/slots/onboarding/model";
import Coin from "@/components/ui/Coin";
import RectangleWrapper from "@/components/ui/RectangleWrapper";
import ScreenLoader from "@/components/ui/ScreenLoader";
import Skeleton from "@/components/ui/Skeleton";
import { SlotsService } from "@/lib/actions";
import { cn, numberFormat } from "@/lib/utils";
import { useQuery } from "@tanstack/react-query";
import { createFileRoute, Link } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";

const SlotsLobbyPage = () => {
	const { t } = useTranslation();

	const availableSlots = [
		{
			img: "/Neptun_banner-min.png",
			title: "Neptun Slot",
			subtitle: "min. 500",
			href: "/slots/lobby/blue/",
		},
		{
			img: "/Atlantida_banner-min.png",
			title: "Atlantida Slot",
			subtitle: "min. 100K",
			href: "/slots/lobby/gold/",
		},
	];

	const { data, isLoading } = useQuery({
		queryKey: ["balance"],
		queryFn: async () => {
			return await SlotsService.getBalance();
		},
		staleTime: 1000 * 5,
	});

	const balance = data?.payload?.current_balance ?? 0;

	const { step, setOnboardingOnStep } = useSlotsOnboarding();

	return (
		<main className="h-screen flex flex-col gap-10 p-6 pb-[128px] relative">
			<div className="flex flex-col items-center gap-4">
				<h1 className="text-2xl flex items-center space-x-2 font-semibold text-center transition ease-in-out animate-in duration-300 slide-in-from-left-10 fade-in-0">
					<span>{t("slots.lobby")}</span>
					<button onClick={() => setOnboardingOnStep(1)}>
						<QuestionIcon />
					</button>
				</h1>
				<div className="flex items-center justify-between w-full relative">
					<RectangleWrapper
						className={cn(
							"flex items-center space-x-1.5 py-1 px-4",
							step === 1 ? "z-10" : "",
						)}
					>
						{isLoading ? (
							<div className="w-10 h-6 py-1">
								<Skeleton />
							</div>
						) : (
							<span>{numberFormat(balance)}</span>
						)}
						<Coin className="w-5 h-5" />
					</RectangleWrapper>
					<Link
						to="/slots/wallet/deposit"
						className={cn(
							"flex items-center space-x-2 py-1 px-4 rounded-xl border border-blue-300 bg-blue-500",
							step === 2 ? "z-10" : "",
						)}
					>
						<IconoirWalletSolid className="text-white w-5 h-5" />
						<span>{t("slots.topUp")}</span>
					</Link>

					{/* Onboarding */}
					{step === 1 ? (
						<div className="z-10 absolute top-14 left-0 text-sm max-w-64 text-white/80 backdrop-blur rounded-md px-2 py-2">
							<p>{t("slots.onboarding1")}</p>
						</div>
					) : null}
					{step === 2 ? (
						<div className="z-10 absolute top-14 right-0 text-sm text-right max-w-64 text-white/80 backdrop-blur rounded-md px-2 py-2">
							<p>{t("slots.onboarding2")}</p>
						</div>
					) : null}
				</div>

				{step === 3 ? (
					<div className="z-40 absolute bottom-28 text-center left-1/2 -translate-x-1/2 text-sm max-w-64 text-white/80 backdrop-blur rounded-md px-2 py-2">
						<p>{t("slots.onboarding3")}</p>
					</div>
				) : null}

				<div className="grid grid-cols-2 gap-4 w-full">
					{availableSlots.map((slot) => (
						<Link key={slot.title} to={slot.href}>
							<RectangleWrapper>
								<div className="h-56 text-center flex flex-col justify-end relative">
									<img
										src={slot.img}
										width={158}
										height={224}
										className="h-56 w-full object-cover rounded-xl"
										alt=""
									/>
									<div className="absolute rounded-xl inset-0 [background:linear-gradient(180deg,rgba(0,0,0,0)_50%,#000000_90%)] opacity-8"></div>
									<div className="absolute bottom-4 left-1/2 -translate-x-1/2 w-full">
										<p className="font-bold text-sm">{slot.title}</p>
										<p className="text-xs text-white/30">{slot.subtitle}</p>
									</div>
								</div>
							</RectangleWrapper>
						</Link>
					))}
				</div>
			</div>
		</main>
	);
};

export const Route = createFileRoute("/slots/lobby/")({
	component: SlotsLobbyPage,
});
