"use client";

import { createFileRoute, useParams, useSearch } from "@tanstack/react-router";
import LeagueClient from "../../components/league/client";

import { LevelType } from "@/types";

const LeaguePage = () => {
	const searchParams = useSearch({ from: "/league/" });
	// @ts-expect-error: todo lvl
	const level = Number(searchParams.lvl) as LevelType;

	return (
		<main className="min-h-screen flex flex-col gap-8 p-6 pb-[128px]">
			<LeagueClient initLevel={level} />
		</main>
	);
};

export const Route = createFileRoute("/league/")({
	component: LeaguePage,
});
