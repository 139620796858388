import ArrowDoubleRightIcon from "@/components/icons/ArrowRightIcon";
import {
	getOnboardingScheme,
	ONBOARDING_STEPS,
	useSlotsOnboarding,
} from "@/components/slots/onboarding/model";
import { createFileRoute, Outlet, useRouter } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";

function SlotsLayout() {
	const { step, setStep, skipOnboarding, onboardingEnabled } =
		useSlotsOnboarding();
	const router = useRouter();

	const { t } = useTranslation();

	const handleNextStep = () => {
		if (step >= ONBOARDING_STEPS) {
			skipOnboarding();
			return;
		}
		const scheme = getOnboardingScheme();
		const hasNavigation = scheme[step];
		if (hasNavigation) {
			router.history.push(hasNavigation.href);
		}
		setStep(step + 1);
	};

	return (
		<>
			{onboardingEnabled ? (
				<>
					<div className="absolute top-8 w-full flex items-center justify-between z-20 px-6">
						<div>
							{step}/{ONBOARDING_STEPS}
						</div>
						<button onClick={skipOnboarding} className="flex items-center">
							<span>{t("slots.skip")}</span>{" "}
							<ArrowDoubleRightIcon className="text-white" />
						</button>
					</div>
					<button
						onClick={handleNextStep}
						className="absolute top-1/2 -translate-y-1/2 z-20 right-4"
					>
						{t("slots.next")} {"->"}
					</button>
				</>
			) : null}
			<Outlet />
			{onboardingEnabled ? (
				<div className="absolute top-0 left-0 w-screen h-screen bg-black/80"></div>
			) : null}
		</>
	);
}

export const Route = createFileRoute("/slots")({
	component: SlotsLayout,
});
