import { HtmlHTMLAttributes } from "react";

interface IconProps extends HtmlHTMLAttributes<SVGElement> {}

const WheelCircle: React.FC<IconProps> = (props) => {
	return (
		<svg
			width="59"
			height="59"
			viewBox="0 0 59 59"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M29.9952 58.9907C46.0138 58.9907 58.9987 46.0051 58.998 29.9866C58.9972 13.968 46.011 0.982422 29.9924 0.982422C13.9739 0.982422 0.988905 13.968 0.989675 29.9866C0.990444 46.0051 13.9767 58.9907 29.9952 58.9907Z"
				fill="url(#paint0_radial_209_586)"
			/>
			<path
				d="M30.0151 56.0283C44.3794 56.0283 56.0234 44.3837 56.0227 30.0195C56.022 15.6553 44.3769 4.01074 30.0127 4.01074C15.6484 4.01074 4.00446 15.6553 4.00514 30.0195C4.00582 44.3837 15.6509 56.0283 30.0151 56.0283Z"
				fill="url(#paint1_linear_209_586)"
			/>
			<defs>
				<radialGradient
					id="paint0_radial_209_586"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(29.9851 30.0076) rotate(89.9986) scale(63.366 63.366)"
				>
					<stop stop-color="#08146E" />
					<stop offset="1" stop-color="#01011A" />
				</radialGradient>
				<linearGradient
					id="paint1_linear_209_586"
					x1="60.4277"
					y1="32.5574"
					x2="-13.4998"
					y2="26.392"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0.28" stop-color="#303030" />
					<stop offset="1" stop-color="#000604" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default WheelCircle;
