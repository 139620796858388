import { cn } from "@/lib/utils";

interface CoinProps {
	className?: string;
}

const Coin: React.FC<CoinProps> = ({ className }) => {
	return (
		<img
			src="/gold_coin.png"
			alt="coin"
			width={500}
			height={500}
			className={cn("w-4 h-4", className)}
		/>
	);
};

export default Coin;
