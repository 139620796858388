import { useEffect, useState } from "react";
import toast from "react-hot-toast";

import { cn } from "@/lib/utils";
import useTelegram from "@/hooks/useTelegram";
import { completeQuest, getIsBooster, getIsMember } from "@/lib/actions";
import { useI18n } from "@/locales/client";

import Coin from "@/components/ui/Coin";
import HourglassIcon from "@/components/icons/HourglassIcon";
import CheckCircleIcon from "@/components/icons/CheckCircleIcon";

import { QuestType } from "@/types";
import { useQueryClient } from "@tanstack/react-query";
import LoaderIcon from "@/components/icons/LoaderIcon";
import { useBitgetWallet } from "@/hooks/useBitgetWallet";

interface TaskProps {
	quest: QuestType;
}

const Task: React.FC<TaskProps> = ({ quest }) => {
	const t = useI18n("earn");

	const [loading, setLoading] = useState(false);

	const queryClient = useQueryClient();

	const telegram = useTelegram();
	const { connected, connector, error } = useBitgetWallet();
	const [isLoadingBitget, setIsLoadingBitget] = useState(false);

	const handleBitgetIntegration = async () => {
		setIsLoadingBitget(true);
		await connector.connect();

		// } finally {
		// 	setIsLoadingBitget(false);
		// }
	};

	useEffect(() => {
		if (error) {
			setIsLoadingBitget(false);
		}
	}, [error]);

	const [sent, setSent] = useState(false);

	useEffect(() => {
		if (!connected || quest.completed || quest.type !== "bitget_wallet") {
			return;
		}

		if (sent) {
			return;
		}

		console.log({ connected });
		(async () => {
			setSent(true);
			setIsLoadingBitget(true);

			try {
				await completeQuest(quest.type);
				await queryClient.invalidateQueries({ queryKey: ["daily-rewards"] });
				await queryClient.invalidateQueries({ queryKey: ["quests"] });
				await queryClient.invalidateQueries({ queryKey: ["user"] });

				connector.disconnect();
			} finally {
				setIsLoadingBitget(false);
			}
		})();
	}, [connected, quest, sent]);

	const onComplete = async () => {
		try {
			setLoading(true);

			if (quest.type?.includes("boost_telegram")) {
				const chatId =
					quest.action_channel_id || quest.action_url?.split("?")?.[0];

				const isBooster = await getIsBooster(`@${chatId}`);

				if (!isBooster?.is_boost) {
					toast.error("Task not completed", { icon: "ℹ️" });

					telegram?.openTelegramLink(`https://t.me/${quest.action_url}`);
				} else {
					await completeQuest(quest.type);
				}
			} else if (quest.type?.includes("telegram_cross")) {
				await completeQuest(quest.type);
				if (quest.action_url) {
					telegram?.openTelegramLink(`https://t.me/${quest.action_url}`);
				}
			} else if (quest.type?.includes("telegram") && quest.action_url) {
				const chatId = quest.action_channel_id || quest.action_url;
				const isMember = await getIsMember(`@${chatId}`);

				if (!isMember?.is_member) {
					toast.error("Task not completed", { icon: "ℹ️" });

					telegram?.openTelegramLink(`https://t.me/${quest.action_url}`);
				} else {
					await completeQuest(quest.type);
				}
			} else if (quest.action_url) {
				await completeQuest(quest.type);

				if (quest.action_url) {
					telegram?.openLink(quest.action_url);
				}
			} else if (quest.type?.includes("bitget_wallet")) {
				if (!connected) {
					handleBitgetIntegration();
					return;
				} else {
					await completeQuest(quest.type);
				}
			} else {
				await completeQuest(quest.type);
			}

			await queryClient.invalidateQueries({ queryKey: ["daily-rewards"] });
			await queryClient.invalidateQueries({ queryKey: ["quests"] });
			await queryClient.invalidateQueries({ queryKey: ["user"] });
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const black = quest.completed || loading;

	return (
		<li
			onClick={() => {
				if (
					(quest.type?.includes("telegram") || quest.type?.includes("cross")) &&
					quest.action_url
				) {
					telegram?.openTelegramLink(`https://t.me/${quest.action_url}`);
				} else if (quest.action_url) {
					telegram?.openLink(quest.action_url);
				} else if (quest.type?.includes("bitget_wallet")) {
					handleBitgetIntegration();
				}
			}}
			className={cn(
				"flex items-center justify-between px-4 py-[15px] rounded-xl border",
				black
					? "border-gray-300 bg-gradient-to-b from-gray-500 to-black"
					: "bg-blue-500 border-blue-300",
			)}
		>
			<div className="flex items-center gap-4">
				<img
					src={
						quest.image
							? `${import.meta.env.VITE_PUBLIC_BASE_URL}${quest.image}`
							: "/btc.png"
					}
					alt="image"
					width={360}
					height={360}
					className="w-8 h-8"
				/>

				<div className="flex flex-col gap-1">
					<p className="text-[13px] font-medium leading-[18px]">{quest.text}</p>

					<div className="flex items-center gap-1">
						<Coin />

						<p className="text-[13px] font-medium leading-[18px]">{`+${quest.reward}`}</p>
					</div>
				</div>
			</div>

			{loading ? (
				<HourglassIcon className="w-6 h-6 text-gray-100" />
			) : quest.completed ? (
				<CheckCircleIcon className="w-6 h-6 text-positive" />
			) : (
				<button
					onClick={(e) => {
						e.stopPropagation();
						onComplete();
					}}
					disabled={isLoadingBitget}
					className="inline-flex items-center justify-center px-6 py-2 bg-white text-black rounded-full space-x-2"
				>
					{quest.type?.includes("bitget_wallet") && isLoadingBitget ? (
						<LoaderIcon className="w-4 h-4 animate-spin" />
					) : null}
					<p className="text-sm text-black font-medium leading-[18px]">
						{quest.type?.includes("bitget_wallet") ? t("claim") : t("join")}
					</p>
				</button>
			)}
		</li>
	);
};

export default Task;
