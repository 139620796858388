import Coin from "@/components/ui/Coin";
import RectangleWrapper from "@/components/ui/RectangleWrapper";
import Skeleton from "@/components/ui/Skeleton";
import { characters } from "@/constants";
import { getMeClient, SlotsService } from "@/lib/actions";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";

import React, { FormEvent, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const SlotsDepositPage = () => {
	const { t } = useTranslation();
	const {
		data,
		refetch,
		isLoading: userLoading,
	} = useQuery({
		queryKey: ["user"],
		queryFn: async () => {
			return await getMeClient();
		},
	});

	const balance = data?.coins ?? 0;

	const [amount, setAmount] = useState(1000);

	const [depositing, setDepositing] = useState(false);

	const queryClient = useQueryClient();

	const handleDeposit = async (e: FormEvent) => {
		e.preventDefault();
		if (!amount) {
			return false;
		}

		setDepositing(true);

		try {
			const res = await SlotsService.deposit(amount);
			await refetch();
			await queryClient.invalidateQueries({
				queryKey: ["balance"],
			});
			await queryClient.invalidateQueries({
				queryKey: ["transactions-history"],
			});
			if (res.ok) {
				toast.success(t("slots.toppedUp"));
			} else {
				toast.error(t("slots.depositError"));
			}
		} catch (err) {
			console.error(err);
			toast.error(t("slots.depositError"));
		} finally {
			setDepositing(false);
		}
		return false;
	};

	const handleSetAmount: React.ChangeEventHandler<HTMLInputElement> = (e) => {
		const number = Number(e.target.value);

		if (typeof number === "number" && !Number.isNaN(number)) {
			if (!data?.coins) {
				setAmount(0);
			} else {
				number >= data.coins ? setAmount(data.coins) : setAmount(number);
			}
		}
	};

	return (
		<main className="h-screen flex flex-col gap-10 p-6 pb-[128px]">
			<div className="flex flex-col items-center gap-4">
				{userLoading ? (
					<div className="flex items-center justify-center space-x-2">
						<div className="w-[43px] h-[43px]">
							<Skeleton />
						</div>
						<div className="ml-2 w-24 h-4">
							<Skeleton />
						</div>
					</div>
				) : (
					<div className="flex items-center justify-center space-x-2">
						<img
							src={characters[data?.level ?? 1].image}
							width={43}
							height={43}
							alt="avatar"
							className="object-cover"
						/>
						<p>{data?.username}</p>
					</div>
				)}
				<h1 className="text-2xl flex items-center space-x-2 font-semibold text-center transition ease-in-out animate-in duration-300 slide-in-from-left-10 fade-in-0">
					<span>{t("slots.topUp")}</span>
				</h1>
				<div className="text-center">
					<p className="text-xl font-medium">{t("slots.inGameBalance")}</p>
					<div className="flex items-center justify-center space-x-2 mt-2">
						{userLoading ? (
							<div className="w-20 h-10">
								<Skeleton />
							</div>
						) : (
							<span className="text-3xl font-semibold">
								{Intl.NumberFormat("ru").format(balance)}
							</span>
						)}
						<Coin className="w-8 h-8" />
					</div>
				</div>
				<div className="space-y-4">
					<RectangleWrapper className="max-w-sm mx-auto p-4">
						<div className="flex items-center justify-between">
							<label
								htmlFor="number-input"
								className="text-sm font-medium flex items-center space-x-2 text-white"
							>
								<Coin className="w-6 h-6" />
								<span>{t("slots.inGame")}</span>
							</label>
							<div className="text-gray-100 text-sm">{t("slots.give")}</div>
						</div>
						<input
							type="text"
							id="number-input"
							aria-describedby="helper-text-explanation"
							className="bg-transparent text-3xl font-semibold block w-full p-1"
							placeholder={Intl.NumberFormat("ru").format(balance)}
							required
							value={amount}
							onChange={handleSetAmount}
						/>
					</RectangleWrapper>
					<RectangleWrapper className="max-w-sm mx-auto p-4">
						<div className="flex items-center justify-between">
							<label
								htmlFor="number-input"
								className="text-sm font-medium flex items-center space-x-2 text-white"
							>
								<Coin className="w-6 h-6" />
								<span>{t("slots.casinoBalance")}</span>
							</label>
							<div className="text-gray-100 text-sm">{t("slots.receive")}</div>
						</div>
						<input
							type="text"
							id="number-input"
							aria-describedby="helper-text-explanation"
							className="bg-transparent text-3xl font-semibold block w-full p-1"
							placeholder={Intl.NumberFormat("ru").format(balance)}
							required
							value={amount}
							onChange={handleSetAmount}
						/>
					</RectangleWrapper>
					<button
						onClick={handleDeposit}
						disabled={depositing}
						className="rounded-full text-xl font-semibold w-full bg-blue-300 border border-blue-300 py-4 mt-4"
					>
						{t("slots.topUp")}
					</button>
				</div>
			</div>
		</main>
	);
};

export const Route = createFileRoute("/slots/wallet/deposit/")({
	component: SlotsDepositPage,
});
