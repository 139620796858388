import { createFileRoute } from "@tanstack/react-router";
import { getMeClient, getUserModal } from "@/lib/actions";

import RootClient from "../components/home/client";
import PassiveProfit from "../components/home/PassiveProfit";
import { useQuery } from "@tanstack/react-query";
import useCreateJwt from "@/hooks/useCreateJwt";
import ScreenLoader from "@/components/ui/ScreenLoader";
import { useEffect, useState } from "react";
import { ProfitModalService } from "@/services/profitModal.service";
import ReferralModal from "../components/home/ReferralModal";
import { RefferalModalService } from "@/services/referralModal.service";

export const Route = createFileRoute("/")({
	component: Index,
});

function Index() {
	const isReady = useCreateJwt();

	const { data } = useQuery({
		queryKey: ["user"],
		queryFn: async () => await getMeClient(),
		enabled: isReady,
	});

	const [passiveProfit, setPassiveProfit] = useState(0);

	const [canShowPassive, setCanShowPassive] = useState(false);

	useEffect(() => {
		if (!data) {
			return;
		}

		const modalWasShown = ProfitModalService.modalWasShown();
		if (modalWasShown) {
			return;
		}

		if (!passiveProfit) {
			setPassiveProfit(data.last_passive_coin_profit);
			ProfitModalService.setIsModalShown();
		}
	}, [data]);

	const { data: refPopupData } = useQuery({
		queryKey: ["ref-popup"],
		queryFn: async () => await getUserModal(),
		enabled: !!data?.refferal_popup,
	});

	const [hadReferral, setHadReferral] = useState(false);

	useEffect(() => {
		if (!data) {
			return;
		}

		if (data && !data.refferal_popup && !hadReferral) {
			setCanShowPassive(true);
			return;
		}

		if (data.refferal_popup) {
			setHadReferral(true);
		}

		if (data.refferal_popup && refPopupData && !refPopupData.show) {
			setCanShowPassive(true);
			return;
		}
	}, [data, refPopupData]);

	const onCloseReferral = () => {
		setCanShowPassive(true);
	};

	const [showRefModal, setShowRefModal] = useState(false);

	useEffect(() => {
		if (refPopupData?.show && !RefferalModalService.modalWasShown()) {
			setShowRefModal(true);
			RefferalModalService.setIsModalShown();
		}

		return () => {
			setShowRefModal(false);
		};
	}, [refPopupData]);

	if (!data) {
		return <ScreenLoader />;
	}

	return (
		<main className="min-h-screen flex flex-col items-center justify-between pt-2 px-6 pb-[128px] bg-blue-600">
			<ReferralModal
				image={refPopupData?.image}
				title={refPopupData?.title}
				description={refPopupData?.description}
				show={showRefModal}
				onClose={onCloseReferral}
			/>

			<PassiveProfit prevProfit={passiveProfit} show={canShowPassive} />

			<RootClient user={data} />
		</main>
	);
}
