import React from "react";
import Coin from "./Coin";

interface RangeSliderTooltipProps {
	value?: string;
	discount?: number;
}

const RangeSliderTooltip = ({ value, discount }: RangeSliderTooltipProps) => {
	return (
		<div className="relative rounded-full bg-blue-200 w-fit">
			<div className="flex items-center space-x-1 text-white px-2 py-1 text-xs">
				<Coin className="w-4 h-4" />
				<span>{value}</span>
			</div>
			<div className="bg-white text-black rotate-12 absolute -top-2 -right-2 text-[8px] font-semibold px-1 py-0.5 rounded-full">
				-{discount}%
			</div>
			<div className="rotate-45 bg-blue-200 absolute left-1/2 -translate-x-1/2 -bottom-0.5 w-2 h-2 -z-10"></div>
		</div>
	);
};

export default RangeSliderTooltip;
