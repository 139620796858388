import { useEffect } from "react";
import { useAtomValue } from "jotai";
import { useQueryClient } from "@tanstack/react-query";

import { tappingAtom } from "@/lib/atoms";

import { UserType } from "@/types";

const EnergyProvider = () => {
	const queryClient = useQueryClient();

	const tapping = useAtomValue(tappingAtom);

	useEffect(() => {
		let energyInterval: NodeJS.Timeout;

		if (!tapping) {
			energyInterval = setInterval(async () => {
				const currentData = queryClient.getQueryData(["user"]) as UserType;

				if (currentData) {
					const current_energy =
						currentData.energy + 3 > currentData.energy_limit
							? currentData.energy_limit
							: currentData.energy + 3;

					await queryClient.setQueryData(["user"], {
						...currentData,
						energy: current_energy,
					});

					// зачем этот провайдер и почему он спамит энергией каждую секунду?
					// он не только синкает энергию с бэком, но и с UI
					// try {
					// 	await sendTaps({ current_energy, earned: 0 });
					// } catch (error) {
					// 	console.log(error);
					// }
				}
			}, 1000);
		}

		return () => {
			clearInterval(energyInterval);
		};
	}, [tapping]);

	return null;
};

export default EnergyProvider;
