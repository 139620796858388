import { OmniConnect } from "@bitget-wallet/omni-connect";
import { useEffect, useState } from "react";

export const useBitgetWallet = () => {
	const [connector, setConnector] = useState({} as any);
	const [connected, setConnected] = useState(false);
	const [error, setError] = useState(0);

	useEffect(() => {
		const connector = new OmniConnect({
			metadata: {
				name: "Ocean Kombat",
				iconUrl: "https://app-dev.ocean-kombat.com/ok_logo.jpg",
				url: "https://web3.bitget.com",
				privacyPolicyUrl: "",
				termsOfUseUrl: "",
			},
			namespace: {
				eip155: {
					chains: ["1"],
					// methods: ["eth_sign", "personal_sign"],
					// events: ["accountsChanged", "chainChanged"],
				},
			},
		});
		setConnector(connector);

		const subscription = connector.onStatusChange(
			(walletInfo: any) => {
				console.log("onStatusChange", walletInfo);
				const { id, namespaceKey, event, connected, result } = walletInfo;
				switch (event) {
					case "connect":
					case "disconnect":
						setConnected(connected);

						break;
					default:
						break;
				}
			},
			(err: any) => {
				const { code, message } = err;
				console.error(`error stream: code: ${code}, message: ${message}`);
				setError((prev) => prev + 1);
			},
		);
		return () => {
			subscription?.unsubscribe();
		};
	}, []);

	return { connected, connector, error };
};
