import { MutableRefObject } from "react";

const NUMBER_STYLES = `
z-index: 2;
position: absolute;
max-width: 100%;
max-height: 100px;
overflow: hidden;
animation: slideUp 1s ease-out;
pointer-events: none;
color: white;
font-size: 28px;
font-weight: bold;
`;

export const setPoint = ({
	x,
	y,
	target,
	value,
}: {
	x: number;
	y: number;
	target: MutableRefObject<HTMLDivElement>;
	value: number;
}) => {
	const numberEl = document.createElement("div");
	numberEl.innerText = value.toString();

	numberEl.style.cssText = NUMBER_STYLES;
	numberEl.style.left = `${x - 56}px`;
	numberEl.style.top = `${y - 156}px`;

	target.current.append(numberEl);

	const keyframes = [
		{ transform: "translateY(0)", opacity: "1" },
		{ transform: "translateY(-150px)", opacity: 0 },
	];

	const animationOptions = { duration: 900, iterations: 1 };

	const animation = numberEl.animate(keyframes, animationOptions);

	animation.finished.then(() => {
		numberEl.remove();
	});
};
