import { IconProps } from "./types";

const CoinsIcon: React.FC<IconProps> = (props) => {
	return (
		<svg
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M12.6 17C12.6 19.7614 14.8386 22 17.6 22C20.3614 22 22.6 19.7614 22.6 17C22.6 14.2386 20.3614 12 17.6 12C14.8386 12 12.6 14.2386 12.6 17ZM12.6 17C12.6 15.8742 12.9721 14.8353 13.6 13.9995V5M12.6 17C12.6 17.8254 12.8 18.604 13.1541 19.2901C12.3116 20.0018 10.3658 20.5 8.09998 20.5C5.06241 20.5 2.59998 19.6046 2.59998 18.5V5M13.6 5C13.6 6.10457 11.1375 7 8.09998 7C5.06241 7 2.59998 6.10457 2.59998 5M13.6 5C13.6 3.89543 11.1375 3 8.09998 3C5.06241 3 2.59998 3.89543 2.59998 5M2.59998 14C2.59998 15.1046 5.06241 16 8.09998 16C10.289 16 12.1793 15.535 13.0646 14.8618M13.6 9.5C13.6 10.6046 11.1375 11.5 8.09998 11.5C5.06241 11.5 2.59998 10.6046 2.59998 9.5"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default CoinsIcon;
