import { atom, useAtom } from 'jotai';

export const ONBOARDING_STEPS = 14;

export const getOnboardingScheme = (
  locale?: 'ru' | 'en'
): Record<number, { href: string }> => {
  return {
    3: {
      href: `/slots/top`,
    },
    6: {
      href: `/slots/profile`,
    },
    9: {
      href: `/slots/wallet`,
    },
  };
};

export const onboardingStepAtom = atom(0);

export const isOnboardingEnabled = atom((get) => {
  const onboardingStep = get(onboardingStepAtom);
  return onboardingStep > 0;
});

export const useSlotsOnboardingStep = () => {
  return useAtom(onboardingStepAtom);
};

export const useSlotsOnboarding = () => {
  const [step, setStep] = useSlotsOnboardingStep();
  const [onboardingEnabled] = useAtom(isOnboardingEnabled);

  const skipOnboarding = () => {
    setStep(0);
  };

  const setOnboardingOnStep = (step: number) => {
    setStep(step);
  };

  return {
    step,
    setStep,
    skipOnboarding,
    setOnboardingOnStep,
    onboardingEnabled,
  };
};
