import React from "react";
import LoaderIcon from "../icons/LoaderIcon";

const ScreenLoader = () => {
	return (
		<main className="w-screen h-screen fixed top-0 left-0 z-[50] bg-black flex flex-col justify-center items-center">
			<LoaderIcon className="w-6 h-6 animate-spin" />
		</main>
	);
};

export default ScreenLoader;
