import { useAtom } from "jotai";
import { debounce } from "lodash";
import toast from "react-hot-toast";
import { tappingAtom } from "@/lib/atoms";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useRef, useState } from "react";

import { cn } from "@/lib/utils";
import { setPoint } from "@/lib/setPoint";
import useTelegram from "@/hooks/useTelegram";
import { getMeClient, sendTaps } from "@/lib/actions";

import Coin from "@/components/ui/Coin";
import Emoji from "@/components/ui/Emoji";
import ChevronRight from "@/components/icons/ChevronRight";

import Boosters from "./Boosters";

import { UserType } from "@/types";

import { characters, LEVEL_BY_COST } from "@/constants";
import { Link } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";

interface RootClientProps {
	user: UserType;
}

const RootClient: React.FC<RootClientProps> = ({ user }) => {
	const { t } = useTranslation();

	const queryClient = useQueryClient();

	const { data } = useQuery({
		queryKey: ["user"],
		queryFn: async () => await getMeClient(),
	});

	const coinRef = useRef<HTMLDivElement>(null!);

	const [tapping, setTapping] = useAtom(tappingAtom);

	const [count, setCount] = useState(0);

	const telegram = useTelegram();

	const debounceSave = useCallback(
		debounce(
			async ({
				current_energy,
				earned,
			}: {
				current_energy: number;
				earned: number;
			}) => {
				try {
					await sendTaps({ current_energy, earned });
				} catch (error) {
					console.log(error);

					toast.error(t("exchange.error"));
				}

				setTapping(false);

				setCount(0);

				// он тут не нужен, можно вычислять и оптимистично обновлять UI
				// await queryClient.invalidateQueries({ queryKey: ["user"] });

				import.meta.env.VITE_PUBLIC_DEBUG === "true" && toast.success("Synced");
			},
			// почему 200, тут можно сделать и выше
			1000,
		),
		[],
	);

	const [touching, setTouching] = useState(false);

	const onTouch = (e: TouchEvent) => {
		e.preventDefault();

		setTouching(true);

		for (let i = 0; i < e.touches.length; i++) {
			const currentData = queryClient.getQueryData(["user"]) as UserType;

			const perClick = currentData.multitap_lvl;

			if (currentData.energy >= perClick) {
				if (!tapping) {
					setTapping(true);
				}

				const { clientX, clientY } = e.touches.item(i)!;

				setPoint({ x: clientX, y: clientY, target: coinRef, value: perClick });

				setCount((prev) => prev + perClick);

				const coins = currentData.coins + perClick;

				// note: тут UI обновление, зачем еще синк дополнительный в debounceSave?
				queryClient.setQueryData(["user"], {
					...currentData,
					coins,
					energy: currentData.energy - perClick,
					level:
						coins > LEVEL_BY_COST[currentData.level]
							? currentData.level + 1
							: currentData.level,
				});
			} else {
				telegram?.HapticFeedback.notificationOccurred("error");

				toast.error(t("exchange.lackOf"), { duration: 200 });
			}
		}
	};

	const afterTouch = (e: TouchEvent) => {
		e.preventDefault();
		setTouching(false);
	};

	useEffect(() => {
		(async () => {
			const newEnergy = ((Date.now() - data!.energy_change) / 1000) * 3;
			console.log(newEnergy);

			const current_energy =
				data!.energy + newEnergy > user.energy_limit
					? user.energy_limit
					: data!.energy + newEnergy;

			await queryClient.setQueryData(["user"], (oldUser: UserType) => ({
				...oldUser,
				current_energy: oldUser.energy + newEnergy,
			}));

			try {
				// это как-то валидируется на бэке?
				await sendTaps({ current_energy, earned: 0 });
			} catch (error) {
				console.log(error);
			}

			// тут не надо(?), приводит к повторному запросу к /me
			// или надо, чтобы показать новое значение coins?
			await queryClient.invalidateQueries({ queryKey: ["user"] });
		})();

		coinRef.current.addEventListener("touchstart", onTouch);
		coinRef.current.addEventListener("touchend", afterTouch);

		return () => {
			coinRef.current?.removeEventListener("touchstart", onTouch);
			coinRef?.current?.removeEventListener("touchend", afterTouch);
		};
	}, []);

	useEffect(() => {
		if (count !== 0) {
			telegram?.HapticFeedback.impactOccurred("medium");

			// тут мы энергию почему передаем с фронтенда?
			// note: зачем вообще энергию передавать с фронта
			debounceSave({ current_energy: data?.energy!, earned: count });
		}
	}, [count]);

	return (
		<>
			<Link
				to={`/league?lvl=${data!.level}`}
				className={cn("flex flex-col items-center self-stretch", "gap-6")}
			>
				<div className="flex items-center gap-2 transition ease-in-out animate-in duration-300 slide-in-from-left-10 fade-in-0">
					<div className="w-8 h-8 rounded-full relative">
						<img
							src={characters[data!.level].image}
							alt="avatar"
							className="absolute object-cover"
						/>
					</div>

					<p>{data!.username}</p>
				</div>

				<div className="flex flex-col gap-1 self-stretch transition ease-in-out animate-in duration-300 slide-in-from-right-10 fade-in-0">
					<div className="bg-blue-500 rounded-full h-4">
						<div
							style={{
								width: `${
									(data!.level * 100) / Object.keys(characters).length
								}%`,
							}}
							className="rounded-full bg-gradient-to-r from-cerulean-200 to-cerulean-75 h-full"
						/>
					</div>

					<div className="flex items-center justify-between text-xs">
						<div className="flex items-center">
							<p>{characters[data!.level].name}</p>

							<ChevronRight className="w-3.5 h-3.5" />
						</div>

						<p>{`${data!.level}/${Object.keys(characters).length}`}</p>
					</div>
				</div>
			</Link>

			<div className="flex flex-col items-center gap-6">
				<div
					ref={coinRef}
					className={cn(
						"rounded-full border border-blue-500 aspect-square h-[38.1vh] shrink-0 relative transition ease-in-out animate-in duration-300 zoom-in-[.8] fade-in-0",
						"[box-shadow:0px_0px_80px_24px_#071160] [background:radial-gradient(50%_50%_at_50%_50%,#08146E_57.69%,#01011A_100%)]",
					)}
				>
					<img
						src={characters[data!.level].image}
						alt="character"
						width={2000}
						height={2000}
						sizes="50vw"
						className={cn(
							"w-auto h-[78%] absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%] transform-gpu transition-transform",
							data?.energy && touching ? "scale-90" : "scale-100",
							data?.level && data.level > 10 ? 'h-full' : 'h-[78%]'
						)}
					/>
				</div>

				<div className="flex flex-col items-center gap-4 relative z-[2]">
					<div className="flex items-center gap-4">
						<Coin className="w-12 h-12" />

						<h1
							className={cn(
								"font-semibold leading-[48px] text-nowrap overflow-hidden",
								data?.coins && data.coins >= 1_000_000_000
									? "text-[28px]"
									: "text-[38px]",
							)}
						>
							{data?.coins.toLocaleString("en-US").replaceAll(",", " ")}
						</h1>
					</div>

					<div className="flex items-center">
						<Emoji src="/lightning.png" className="w-[18px] h-[18px]" />

						<p className="text-[13px] font-medium leading-[18px]">{`${data?.energy} / ${data?.energy_limit}`}</p>
					</div>
				</div>
			</div>

			<div className="mt-2 w-full">
				<Boosters user={data!} />
			</div>
			<Link to="/slots" className="w-full mt-1">
				<div className="w-full rounded-xl  text-white py-2 px-4 flex items-center justify-between border border-blue-300 bg-blue-500/20">
					<div className="text-[13px]">{t("exchange.gameRoom")}</div>
					<svg
						width="24px"
						height="24px"
						viewBox="0 0 24 24"
						strokeWidth="1.5"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						color="currentColor"
					>
						<path
							d="M3 12L21 12M21 12L12.5 3.5M21 12L12.5 20.5"
							stroke="currentColor"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						></path>
					</svg>
				</div>
			</Link>
		</>
	);
};

export default RootClient;
