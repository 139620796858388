import { HtmlHTMLAttributes } from "react";

interface IconProps extends HtmlHTMLAttributes<SVGElement> {}

const WheelArrow: React.FC<IconProps> = (props) => {
	return (
		<svg
			width="16"
			height="43"
			viewBox="0 0 16 43"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M15.8069 0.172778V37.3985L8.00013 42.5975L0.193359 37.3985V0.172778C2.78515 0.0628282 5.39264 0 8.00013 0C10.6076 0 13.2151 0.0471212 15.8069 0.172778Z"
				fill="url(#paint0_linear_209_584)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_209_584"
					x1="8.00014"
					y1="42.5975"
					x2="8.00014"
					y2="0"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="white" />
					<stop offset="1" stop-color="#01011A" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default WheelArrow;
