import { useTimer } from "react-timer-hook";

import { cn, formatSeconds } from "@/lib/utils";
import { useI18n } from "@/locales/client";
import { Link } from "@tanstack/react-router";

interface WheelBannerProps {
	lastWheelSpin: EpochTimeStamp;
}

const WheelBanner: React.FC<WheelBannerProps> = ({ lastWheelSpin }) => {
	const t = useI18n("mine");

	const expiryTimestamp = new Date(lastWheelSpin);

	expiryTimestamp.setDate(expiryTimestamp.getDate() + 1);

	const { totalSeconds } = useTimer({
		expiryTimestamp,
	});

	return (
		<Link
			to="/mine/wheel"
			className={cn(
				"flex items-center justify-between px-4 py-[15px] border border-gray-50 rounded-xl self-stretch relative overflow-hidden h-[170px]",
				"[background:radial-gradient(174.16%_99.64%_at_50%_100.02%,#DAB045_16.76%,#F8D621_100%)]",
			)}
		>
			<div className="flex flex-col gap-2.5 max-w-[50%]">
				<div className="flex flex-col gap-1">
					<p className="text-lg text-black font-semibold leading-6">
						{t("spinTo")}
					</p>

					<p className="text-xs text-black">{formatSeconds(totalSeconds)}</p>
				</div>

				<button className="inline-flex px-6 py-2 rounded-full bg-white w-fit">
					<p className="text-sm text-black font-medium leading-[18px]">
						{t("spin")}
					</p>
				</button>
			</div>

			<img
				src="/banner_wheel.png"
				width={532}
				height={400}
				alt="wheel"
				className="w-auto h-[158px] absolute bottom-[-28px] right-[-20px]"
			/>
		</Link>
	);
};

export default WheelBanner;
