import useTelegram from "@/hooks/useTelegram";
import i18next from "../locales/client";

const LocaleProvider = ({ children }: { children: React.ReactNode }) => {
	const telegram = useTelegram();
	const tgLocale = telegram?.initDataUnsafe?.user?.language_code;

	const lcl = ["en", "ru"].includes(tgLocale ?? "en") ? tgLocale ?? "en" : "en";

	i18next.changeLanguage(lcl);

	return <>{children}</>;
};

export default LocaleProvider;
