const REFFERAL_MODAL_KEY = 'referral_modal';

const modalWasShown = () => {
  return !!window.sessionStorage.getItem(REFFERAL_MODAL_KEY);
};

const setIsModalShown = () => {
  return window.sessionStorage.setItem(REFFERAL_MODAL_KEY, '1');
};

export const RefferalModalService = {
  modalWasShown,
  setIsModalShown,
};
