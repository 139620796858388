import QuestionIcon from "@/components/icons/QuestionIcon";
import { useSlotsOnboarding } from "@/components/slots/onboarding/model";
import Coin from "@/components/ui/Coin";
import RectangleWrapper from "@/components/ui/RectangleWrapper";
import Skeleton from "@/components/ui/Skeleton";
import { characters } from "@/constants";
import { SlotsService } from "@/lib/actions";
import { cn, numberFormat } from "@/lib/utils";
import { useQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const SlotsTopPage = () => {
	const { t } = useTranslation();
	const ACTIVE_TABS = {
		DAILY: 0,
		WEEKLY: 1,
	};

	const { step, setOnboardingOnStep } = useSlotsOnboarding();

	const [activeTab, setActiveTab] = useState(ACTIVE_TABS.DAILY);

	const { data: leaderboardData, isLoading } = useQuery({
		queryKey: ["slots-leaderboard", activeTab],
		queryFn: async () => {
			return await SlotsService.getLeaderboard(
				activeTab === ACTIVE_TABS.DAILY ? "24h" : "7d",
			);
		},
		staleTime: 1000 * 20,
	});

	return (
		<main className="h-screen flex flex-col gap-10 p-6 pb-[128px]">
			<div className="flex flex-col items-center gap-4">
				<h1 className="text-2xl flex items-center space-x-2 font-semibold text-center transition ease-in-out animate-in duration-300 slide-in-from-left-10 fade-in-0">
					<span>{t("slots.top")}</span>
					<button onClick={() => setOnboardingOnStep(4)}>
						<QuestionIcon />
					</button>
				</h1>
				<div className="flex items-center justify-between w-full relative">
					<button
						onClick={() => setActiveTab(ACTIVE_TABS.DAILY)}
						className={cn(
							"py-1 px-4 rounded-xl border border-blue-300 w-full",
							activeTab === ACTIVE_TABS.DAILY ? "bg-blue-500" : "",
							step === 4 ? "z-10" : "",
						)}
					>
						{t("slots.day")}
					</button>
					<button
						onClick={() => setActiveTab(ACTIVE_TABS.WEEKLY)}
						className={cn(
							"py-1 px-4 rounded-xl border border-blue-300 ml-4 w-full",
							activeTab === ACTIVE_TABS.WEEKLY ? "bg-blue-500" : "",
							step === 5 ? "z-10" : "",
						)}
					>
						{t("slots.week")}
					</button>

					{/* Onboarding */}
					{step === 4 ? (
						<div className="z-10 absolute top-12 left-0 text-sm max-w-64 text-white/80 backdrop-blur-md rounded-md px-2 py-2">
							<p>{t("slots.onboarding4")}</p>
						</div>
					) : null}
					{step === 5 ? (
						<div className="z-10 absolute top-12 right-0 text-sm text-right max-w-64 text-white/80 backdrop-blur-md rounded-md px-2 py-2">
							<p>{t("slots.onboarding5")}</p>
						</div>
					) : null}
				</div>

				{step === 6 ? (
					<div className="z-40 absolute bottom-28 text-center left-1/2 -translate-x-1/2 text-sm max-w-64 text-white/80 backdrop-blur-md rounded-md px-2 py-2">
						<p>{t("slots.onboarding6")}</p>
					</div>
				) : null}

				<div className="w-full">
					<div className="space-y-2 overflow-auto h-[440px] pb-8">
						{isLoading ? (
							[1, 2, 3, 4, 5, 6].map((item) => {
								return (
									<RectangleWrapper
										key={item}
										className="flex items-center justify-between w-full px-4 py-2"
									>
										<div className="flex items-center space-x-2">
											<div className="w-[49px] h-[49px]">
												<Skeleton />
											</div>
											<div>
												<div className="w-24 h-4">
													<Skeleton />
												</div>
												<div className="flex items-center space-x-2 mt-1">
													<div className="w-10 h-4">
														<Skeleton />
													</div>
													<Coin className="w-6 h-6" />
												</div>
											</div>
										</div>
									</RectangleWrapper>
								);
							})
						) : leaderboardData?.ok && leaderboardData?.payload ? (
							leaderboardData.payload.map((leader: any, index: number) => {
								return (
									<RectangleWrapper
										key={index}
										className="flex items-center justify-between w-full px-4 py-2"
									>
										<div className="flex items-center space-x-2">
											<img
												// @ts-expect-error: todo typing
												src={characters[leader.user_level].image}
												width={49}
												height={49}
												alt="avatar"
												className="object-cover"
											/>
											<div>
												<p>{leader.username}</p>
												<div className="flex items-center space-x-2">
													<p>
														{numberFormat(Math.abs(leader.session_total_win))}
													</p>
													<Coin className="w-6 h-6" />
												</div>
											</div>
										</div>
									</RectangleWrapper>
								);
							})
						) : (
							<div className="text-center">{t("slots.noData")}</div>
						)}
					</div>
				</div>
			</div>
		</main>
	);
};

export const Route = createFileRoute("/slots/top/")({
	component: SlotsTopPage,
});
