import { useAtom } from "jotai";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

import { logger } from "@/lib/logger";
import { numberFormat } from "@/lib/utils";
import useTelegram from "@/hooks/useTelegram";
import { boostersOpenAtom } from "@/lib/atoms";
import { resetEnergy, upgradeEnergy, upgradeMultitap } from "@/lib/actions";

import Coin from "@/components/ui/Coin";
import Emoji from "@/components/ui/Emoji";
import LoaderIcon from "@/components/icons/LoaderIcon";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";

import { UserType } from "@/types";
import { useTranslation } from "react-i18next";

interface BoostersProps {
	user: UserType;
}

const Boosters: React.FC<BoostersProps> = ({ user }) => {
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);

	const [open, setOpen] = useAtom(boostersOpenAtom);

	const queryClient = useQueryClient();

	const telegram = useTelegram();

	useEffect(() => {
		telegram?.BackButton.onClick(() => setOpen(false));

		if (open) {
			telegram?.BackButton.show();
			telegram?.setHeaderColor("#000");
		} else {
			telegram?.BackButton.hide();
			telegram?.setHeaderColor("#01011A");
		}

		return () => {
			telegram?.BackButton.offClick(() => setOpen(false));
		};
	}, [open]);

	const onFullEnergy = async () => {
		try {
			setLoading(true);

			await resetEnergy();

			await queryClient.invalidateQueries({
				queryKey: ["user"],
			});

			toast.success(t("exchange.refilled"));
		} catch (error) {
			logger.error(error);

			toast.error(t("exchange.error"));
		} finally {
			setLoading(false);
		}
	};

	const onUpgradeEnergy = async () => {
		try {
			setLoading(true);

			await upgradeEnergy();

			await queryClient.invalidateQueries({
				queryKey: ["user"],
			});

			toast.success(t("exchange.success"));
		} catch (error) {
			logger.error(error);

			toast.error(t("exchange.error"));
		} finally {
			setLoading(false);
		}
	};

	const onUpgradeMultitap = async () => {
		try {
			setLoading(true);

			await upgradeMultitap();

			await queryClient.invalidateQueries({
				queryKey: ["user"],
			});

			toast.success(t("exchange.success"));
		} catch (error) {
			logger.error(error);

			toast.error(t("exchange.error"));
		} finally {
			setLoading(false);
		}
	};

	const boosters = [
		{
			title: t("exchange.multitap"),
			imageUrl: "/point.png",
			level: user.multitap_lvl,
			price: user.multitap_lvl_cost,
			onClick: onUpgradeMultitap,
		},
		{
			title: t("exchange.energyLimit"),
			imageUrl: "/battery.png",
			level: user.energy_lvl,
			price: user.energy_lvl_cost,
			onClick: onUpgradeEnergy,
		},
	];

	return (
		<Sheet modal={false} open={open} onOpenChange={setOpen}>
			<SheetTrigger asChild>
				<div className="flex items-center gap-2 self-stretch">
					<div className="flex items-center justify-between flex-1 px-4 py-[7px] rounded-xl border border-blue-300 bg-blue-500">
						<p className="text-[13px] font-medium leading-[18px]">
							{t("exchange.tap")}
						</p>

						<div className="flex items-center gap-1">
							<Coin />

							<p className="text-[13px] font-medium leading-[18px]">
								{user.multitap_lvl}
							</p>
						</div>
					</div>

					<div className="flex items-center justify-between flex-1 px-4 py-[7px] rounded-xl border border-blue-300 bg-blue-500">
						<p className="text-[13px] font-medium leading-[18px]">
							{t("exchange.hour")}
						</p>

						<div className="flex items-center gap-1">
							<Coin />

							<p className="text-[13px] font-medium leading-[18px]">
								{user.passive_profit ? numberFormat(user.passive_profit) : 0}
							</p>
						</div>
					</div>
				</div>
			</SheetTrigger>

			<SheetContent
				side="right"
				close={false}
				overlay={false}
				className="flex flex-col items-center p-6 gap-8 bg-black z-20"
			>
				<div className="flex flex-col items-center gap-4 self-stretch">
					<h1 className="text-2xl font-semibold">
						{t("exchange.dailyBoosters")}
					</h1>

					<button
						onClick={onFullEnergy}
						disabled={loading}
						className="flex items-center gap-4 px-4 py-[15px] rounded-xl bg-blue-500 border border-blue-300 self-stretch"
					>
						<Emoji src="/lightning.png" />

						<div className="flex flex-col items-start gap-1">
							<p>{t("exchange.fullEnergy")}</p>

							<p className="text-[13px] font-medium leading-[18px]">{`${
								user.available_energy_reset
							}/${
								{
									1: 2,
									2: 2,
									3: 3,
									4: 3,
									5: 4,
									6: 4,
									7: 5,
									8: 5,
									9: 6,
									10: 6,
									11: 10,
									12: 15,
									13: 30,
									14: 50,
								}[user.level]
							} ${t("exchange.available")}`}</p>
						</div>

						<div className="inline-flex items-center px-6 py-2 rounded-full bg-white ml-auto">
							{loading ? (
								<LoaderIcon className="w-[18px] h-[18px] animate-spin text-black" />
							) : (
								<p className="text-sm text-black font-medium leading-[18px]">
									{t("exchange.go")}
								</p>
							)}
						</div>
					</button>

					<div className="flex items-center gap-4 p-4 rounded-xl bg-gray-400 self-stretch">
						<Emoji src="/rocket.png" className="w-8 h-8" />

						<div className="flex flex-col gap-1 text-gray-100">
							<p>{t("exchange.turbo")}</p>

							<p className="text-[13px] font-medium leading-[18px]">
								{t("exchange.comingSoon")}
							</p>
						</div>
					</div>
				</div>

				<div className="flex flex-col items-center gap-4 self-stretch">
					<h1 className="text-2xl font-semibold">{t("exchange.boosters")}</h1>

					{boosters.map((booster) => (
						<Booster key={booster.title} loading={loading} {...booster} />
					))}
				</div>
			</SheetContent>
		</Sheet>
	);
};

const Booster = ({
	imageUrl,
	title,
	level,
	price,
	onClick,
	loading,
}: {
	imageUrl: string;
	title: string;
	level: number;
	price: number;
	onClick: () => void;
	loading: boolean;
}) => {
	const { t } = useTranslation();

	return (
		<button
			onClick={onClick}
			disabled={loading}
			className="flex items-center gap-4 px-4 py-[15px] rounded-xl bg-blue-500 border border-blue-300 self-stretch"
		>
			<Emoji src={imageUrl} />

			<div className="flex flex-col items-start gap-1">
				<p>{title}</p>

				<div className="flex items-center text-[13px] font-medium leading-[18px]">
					<div className="flex items-center gap-1">
						<Coin />
						<p>{numberFormat(price)}</p>
					</div>
					&nbsp;
					<p className="opacity-50">{`• ${level} ${t("exchange.lvl")}`}</p>
				</div>
			</div>

			<div className="inline-flex items-center px-6 py-2 rounded-full bg-white ml-auto">
				{loading ? (
					<LoaderIcon className="w-[18px] h-[18px] animate-spin text-black" />
				) : (
					<p className="text-sm text-black font-medium leading-[18px]">
						{t("exchange.upgrade")}
					</p>
				)}
			</div>
		</button>
	);
};

export default Boosters;
