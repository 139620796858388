import { useState } from "react";
import * as Tabs from "@radix-ui/react-tabs";
import { useQuery } from "@tanstack/react-query";

import { getOfferings } from "@/lib/actions";
import useTelegram from "@/hooks/useTelegram";

import Card from "./Card";

import { OfferingTypeType } from "@/types";
import { useTranslation } from "react-i18next";
import Skeleton from "@/components/ui/Skeleton";

const MineClient = () => {
	const { t } = useTranslation();

	const [type, setType] = useState<OfferingTypeType>("pr&team");

	const telegram = useTelegram();

	const { data, isLoading } = useQuery({
		queryKey: ["offerings", { type }],
		queryFn: async () => await getOfferings(type),
	});

	return (
		<Tabs.Root
			value={type}
			onValueChange={(value) => {
				telegram?.HapticFeedback.selectionChanged();
				setType(value as OfferingTypeType);
			}}
			className="w-full"
		>
			<Tabs.List className="flex items-center border-b border-gray-300 relative">
				<Tabs.Trigger
					value="pr&team"
					className="pb-1 px-2 flex-1 text-gray-100 font-semibold transition-colors duration-300 ease-in-out data-[state=active]:text-white"
				>
					{t("mine.strategy")}
				</Tabs.Trigger>

				<Tabs.Trigger
					value="markets"
					className="pb-1 px-2 flex-1 text-gray-100 font-semibold transition-colors duration-300 ease-in-out data-[state=active]:text-white"
				>
					{t("mine.markets")}
				</Tabs.Trigger>

				<Tabs.Trigger
					value="legal"
					className="pb-1 px-2 flex-1 text-gray-100 font-semibold transition-colors duration-300 ease-in-out data-[state=active]:text-white"
				>
					{t("mine.marketing")}
				</Tabs.Trigger>

				<Tabs.Trigger
					value="specials"
					className="pb-1 px-2 flex-1 text-gray-100 font-semibold transition-colors duration-300 ease-in-out data-[state=active]:text-white"
				>
					{t("mine.specials")}
				</Tabs.Trigger>

				<div
					style={{
						transform: {
							"pr&team": "translate(0%)",
							markets: "translate(100%)",
							legal: "translate(200%)",
							specials: "translate(300%)",
						}[type],
					}}
					className="w-1/4 h-0.5 bg-blue-300 transition-transform duration-300 ease-in-out absolute bottom-[-1.4px]"
				/>
			</Tabs.List>

			<div className="grid grid-cols-2 gap-4 pt-6 w-full">
				{isLoading
					? [1, 2, 3].map((v) => (
							<div className="w-full min-w-[168px] max-w-full h-44 py-1">
								<Skeleton />
							</div>
					  ))
					: data?.map((offering, index) => (
							<Card
								key={index}
								offering={offering}
								cardType={type === "specials" ? "special" : "default"}
								big={type === "specials" && index === 0}
							/>
					  ))}
			</div>
		</Tabs.Root>
	);
};

export default MineClient;
