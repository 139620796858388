import { IconProps } from "./types";

const MineIcon: React.FC<IconProps> = (props) => {
	return (
		<svg
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M2.39999 2L4.39999 4M22.4 2L20.4 4M22.4 22L20.4 20M2.39999 22L4.39999 20M2.39999 16H3.89999M8.39999 2V3.5M22.4 8H20.9M16.4 22V20.5M18.4 16H21.9M16.4 2V6M2.39999 8H6.39999M8.39999 22V18M11.8343 7.56569L7.96568 11.4343C7.76767 11.6323 7.66867 11.7313 7.63157 11.8455C7.59894 11.9459 7.59894 12.0541 7.63157 12.1545C7.66867 12.2687 7.76767 12.3677 7.96568 12.5657L11.8343 16.4343C12.0323 16.6323 12.1313 16.7313 12.2455 16.7684C12.3459 16.8011 12.4541 16.8011 12.5545 16.7684C12.6687 16.7313 12.7677 16.6323 12.9657 16.4343L16.8343 12.5657C17.0323 12.3677 17.1313 12.2687 17.1684 12.1545C17.201 12.0541 17.201 11.9459 17.1684 11.8455C17.1313 11.7313 17.0323 11.6323 16.8343 11.4343L12.9657 7.56569C12.7677 7.36768 12.6687 7.26867 12.5545 7.23158C12.4541 7.19895 12.3459 7.19895 12.2455 7.23158C12.1313 7.26867 12.0323 7.36768 11.8343 7.56569Z"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default MineIcon;
