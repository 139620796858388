import { IconProps } from "./types";

const AirdropIcon: React.FC<IconProps> = (props) => {
	return (
		<svg
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M18.0778 20.0902C16.3693 21.3315 14.3117 22 12.2 22C10.0882 22 8.03058 21.3315 6.3221 20.0902M16.5837 3.01206C18.4817 3.93781 20.038 5.44068 21.0294 7.30528C22.0209 9.16989 22.3965 11.3005 22.1026 13.3917M2.29737 13.3916C2.00347 11.3004 2.37915 9.16979 3.37058 7.30519C4.362 5.44059 5.91828 3.93771 7.81634 3.01196M11.6343 6.56569L6.76564 11.4343C6.56763 11.6323 6.46863 11.7313 6.43153 11.8455C6.39891 11.9459 6.39891 12.0541 6.43153 12.1545C6.46863 12.2687 6.56763 12.3677 6.76564 12.5657L11.6343 17.4343C11.8323 17.6323 11.9313 17.7313 12.0454 17.7684C12.1459 17.8011 12.254 17.8011 12.3545 17.7684C12.4686 17.7313 12.5676 17.6323 12.7656 17.4343L17.6343 12.5657C17.8323 12.3677 17.9313 12.2687 17.9684 12.1545C18.001 12.0541 18.001 11.9459 17.9684 11.8455C17.9313 11.7313 17.8323 11.6323 17.6343 11.4343L12.7656 6.56569C12.5676 6.36768 12.4686 6.26867 12.3545 6.23158C12.254 6.19895 12.1459 6.19895 12.0454 6.23158C11.9313 6.26867 11.8323 6.36768 11.6343 6.56569Z"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default AirdropIcon;
