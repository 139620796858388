import { IconProps } from "./types";

const CasinoMachine: React.FC<IconProps> = (props) => {
	return (
		<svg
			width="35"
			height="35"
			viewBox="0 0 35 35"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M17.2094 11.2281C15.0149 11.0968 13.2827 9.23066 12.9031 7.13307C12.381 4.24782 14.8474 6.41431 15.5386 6.283C16.0858 6.17899 16.0732 4.07434 17.5755 3.77963C19.0219 3.63621 19.2509 5.97911 19.5653 6.23286C20.0806 6.64859 21.3936 5.10099 22.1261 5.741C22.5031 6.07049 22.4103 6.65931 22.3274 7.09353C21.9119 9.26922 20.1924 11.1236 17.8953 11.2281V11.8262C19.7231 11.826 21.5548 11.8535 23.3821 11.8255C25.4934 11.7933 25.709 12.164 25.9262 14.1691L26.6678 20.9729C26.8032 22.2122 26.9428 23.4551 26.9551 24.7033L26.9562 26.3053C26.9657 28.8764 26.9991 29.7648 24.1502 29.7635L11.1391 29.7635C9.00221 29.7642 8.38047 29.4029 8.35788 27.1716C8.33852 25.2627 8.38266 23.3657 8.59085 21.4683L9.39164 13.9667C9.48707 13.0211 9.56467 12.0708 10.7498 11.8388C11.3129 11.7286 11.9457 11.8271 12.5239 11.8267L17.2094 11.8262V11.2281ZM17.5755 4.44287C16.709 4.84362 16.7422 6.04993 16.2393 6.62322C15.3504 7.63679 13.8613 6.15378 13.5181 6.28667C13.4391 6.41868 13.579 7.0821 13.6213 7.25081C14.0965 9.1436 15.643 10.6146 17.6495 10.5785C19.7365 10.5947 21.3841 8.83658 21.6759 6.8517C21.6985 6.69841 21.8374 6.24363 21.5591 6.29679C20.8336 6.43536 20.0557 7.3627 19.2354 6.81227C18.5876 6.3774 18.5188 4.40402 17.5755 4.44287ZM11.0489 12.4934C9.93715 12.506 10.07 13.9576 9.99052 14.7711L9.35719 20.6952C9.14249 22.6525 9.02551 24.3526 9.02589 26.3067C9.02644 29.2066 9.05914 29.1001 11.9368 29.097L24.2759 29.0975C25.0624 29.0747 26.0103 29.1363 26.2171 28.1911C26.327 27.6886 26.3011 26.844 26.3014 26.3115C26.3025 24.4107 26.1868 22.8211 25.988 20.9455L25.4876 16.4228C25.4142 15.7777 25.3415 15.1324 25.2817 14.486C25.1208 12.7477 25.2939 12.4277 23.2346 12.4887C22.9726 12.4964 22.7104 12.494 22.4483 12.4935L13.1263 12.4933C12.7231 12.4931 11.3025 12.4101 11.0489 12.4934Z"
				fill="currentColor"
			/>
			<path
				d="M23.1822 22.423L12.1695 22.4233C11.4521 22.4282 10.3508 22.6493 10.2033 21.5658C10.1578 21.2312 10.274 20.5726 10.3124 20.2189L10.896 15.1912C10.999 14.2473 10.9213 13.5373 12.0704 13.4168C12.8486 13.395 13.6332 13.4169 14.412 13.417L21.7632 13.4162C22.3189 13.4161 22.8965 13.3875 23.449 13.4556C24.4265 13.576 24.3782 14.5262 24.4561 15.2806L24.9203 19.4199C25.0014 20.1642 25.3241 21.5302 24.727 22.1213C24.3098 22.5342 23.7144 22.4241 23.1822 22.423ZM12.1404 14.0996C11.76 14.1392 11.6957 14.1687 11.6406 14.5466C11.5794 14.9671 11.5464 15.3938 11.4953 15.8161L11.0927 19.3014C11.0446 19.7356 10.8239 21.1713 10.8863 21.5035C10.9674 21.9352 11.8517 21.7594 12.1638 21.7599L23.0626 21.7609C23.3749 21.7579 23.9204 21.8787 24.1458 21.6588C24.576 21.2391 24.245 19.5709 24.1932 19.0054C24.1061 18.0529 23.9798 17.1081 23.8745 16.1578C23.605 13.7261 24.0665 14.0984 21.5305 14.099L14.2825 14.0996C13.5735 14.0996 12.8473 14.0583 12.1404 14.0996Z"
				fill="currentColor"
			/>
			<path
				d="M21.8306 17.0426C20.6757 17.1519 22.1826 15.0645 22.3828 14.9134C22.5646 14.9429 22.7424 15.0714 22.8729 15.1965C22.7745 15.4871 22.0478 16.8941 21.8306 17.0426Z"
				fill="currentColor"
			/>
			<path
				d="M14.0468 17.1093C13.8202 17.1095 13.5146 16.9917 13.602 16.7021C13.671 16.4733 14.4227 15.1234 14.6815 14.9872C15.7972 14.872 14.1719 17.062 14.0468 17.1093Z"
				fill="currentColor"
			/>
			<path
				d="M17.2094 16.6984C17.3464 16.3557 17.9551 15.1456 18.2889 14.9872C19.0924 14.9615 18.3868 15.9223 18.2302 16.2082C18.0814 16.4799 17.9772 16.9408 17.6495 17.0426C17.394 17.0442 17.3048 16.9289 17.2094 16.6984Z"
				fill="currentColor"
			/>
			<path
				d="M18.3758 20.0083C18.1692 20.2867 17.949 20.6256 17.6491 20.8087C16.824 21.3124 15.9731 20.5011 16.2321 19.6355C16.3284 19.3136 16.6167 18.7072 16.9179 18.5438C18.1194 18.4982 16.1429 20.4783 17.1415 20.2564C17.6346 20.1469 17.821 19.3563 18.1625 19.2954C18.6788 19.2035 18.6098 19.6815 18.3758 20.0083Z"
				fill="currentColor"
			/>
			<path
				d="M22.6936 19.8339C22.4447 20.1953 22.1649 20.6037 21.7651 20.8098C21.2658 21.0671 20.6809 20.9383 20.4222 20.417C20.1656 19.8998 20.571 18.8691 21.0743 18.6313C21.2563 18.5939 21.4602 18.6693 21.5224 18.8598C21.5736 19.0167 20.4472 20.4173 21.3111 20.2387C21.8316 20.131 22.5888 18.3901 22.8729 19.4792C22.8365 19.6146 22.7709 19.7192 22.6936 19.8339Z"
				fill="currentColor"
			/>
			<path
				d="M14.0468 19.8988C13.756 20.2779 13.4169 20.7896 12.9299 20.9193C11.4128 21.3237 11.533 18.8669 12.4815 18.6313C13.5659 18.6947 11.8056 20.04 12.6057 20.2843C13.2596 20.1182 13.4748 19.2488 13.8564 19.1599C14.3166 19.0527 14.3021 19.555 14.0468 19.8988Z"
				fill="currentColor"
			/>
			<path
				d="M17.8953 27.8341C15.2333 28.0028 14.9446 24.2534 17.4476 23.9321C20.038 23.7493 20.4107 27.4523 17.8953 27.8341ZM17.5755 24.5986C15.8412 24.7781 16.0941 27.2759 17.7591 27.1615C19.464 26.9909 19.2276 24.5359 17.5755 24.5986Z"
				fill="currentColor"
			/>
			<path
				d="M17.2094 11.2281C15.0149 11.0968 13.2827 9.23066 12.9031 7.13307C12.381 4.24782 14.8474 6.41431 15.5386 6.283C16.0858 6.17899 16.0732 4.07434 17.5755 3.77963C19.0219 3.63621 19.2509 5.97911 19.5653 6.23286C20.0806 6.64859 21.3936 5.10099 22.1261 5.741C22.5031 6.07049 22.4103 6.65931 22.3274 7.09353C21.9119 9.26922 20.1924 11.1236 17.8953 11.2281V11.8262C19.7231 11.826 21.5548 11.8535 23.3821 11.8255C25.4934 11.7933 25.709 12.164 25.9262 14.1691L26.6678 20.9729C26.8032 22.2122 26.9428 23.4551 26.9551 24.7033L26.9562 26.3053C26.9657 28.8764 26.9991 29.7648 24.1502 29.7635L11.1391 29.7635C9.00221 29.7642 8.38047 29.4029 8.35788 27.1716C8.33852 25.2627 8.38266 23.3657 8.59085 21.4683L9.39164 13.9667C9.48707 13.0211 9.56467 12.0708 10.7498 11.8388C11.3129 11.7286 11.9457 11.8271 12.5239 11.8267L17.2094 11.8262V11.2281ZM17.5755 4.44287C16.709 4.84362 16.7422 6.04993 16.2393 6.62322C15.3504 7.63679 13.8613 6.15378 13.5181 6.28667C13.4391 6.41868 13.579 7.0821 13.6213 7.25081C14.0965 9.1436 15.643 10.6146 17.6495 10.5785C19.7365 10.5947 21.3841 8.83658 21.6759 6.8517C21.6985 6.69841 21.8374 6.24363 21.5591 6.29679C20.8336 6.43536 20.0557 7.3627 19.2354 6.81227C18.5876 6.3774 18.5188 4.40402 17.5755 4.44287ZM11.0489 12.4934C9.93715 12.506 10.07 13.9576 9.99052 14.7711L9.35719 20.6952C9.14249 22.6525 9.02551 24.3526 9.02589 26.3067C9.02644 29.2066 9.05914 29.1001 11.9368 29.097L24.2759 29.0975C25.0624 29.0747 26.0103 29.1363 26.2171 28.1911C26.327 27.6886 26.3011 26.844 26.3014 26.3115C26.3025 24.4107 26.1868 22.8211 25.988 20.9455L25.4876 16.4228C25.4142 15.7777 25.3415 15.1324 25.2817 14.486C25.1208 12.7477 25.2939 12.4277 23.2346 12.4887C22.9726 12.4964 22.7104 12.494 22.4483 12.4935L13.1263 12.4933C12.7231 12.4931 11.3025 12.4101 11.0489 12.4934Z"
				stroke="currentColor"
				stroke-width="0.5"
			/>
			<path
				d="M23.1822 22.423L12.1695 22.4233C11.4521 22.4282 10.3508 22.6493 10.2033 21.5658C10.1578 21.2312 10.274 20.5726 10.3124 20.2189L10.896 15.1912C10.999 14.2473 10.9213 13.5373 12.0704 13.4168C12.8486 13.395 13.6332 13.4169 14.412 13.417L21.7632 13.4162C22.3189 13.4161 22.8965 13.3875 23.449 13.4556C24.4265 13.576 24.3782 14.5262 24.4561 15.2806L24.9203 19.4199C25.0014 20.1642 25.3241 21.5302 24.727 22.1213C24.3098 22.5342 23.7144 22.4241 23.1822 22.423ZM12.1404 14.0996C11.76 14.1392 11.6957 14.1687 11.6406 14.5466C11.5794 14.9671 11.5464 15.3938 11.4953 15.8161L11.0927 19.3014C11.0446 19.7356 10.8239 21.1713 10.8863 21.5035C10.9674 21.9352 11.8517 21.7594 12.1638 21.7599L23.0626 21.7609C23.3749 21.7579 23.9204 21.8787 24.1458 21.6588C24.576 21.2391 24.245 19.5709 24.1932 19.0054C24.1061 18.0529 23.9798 17.1081 23.8745 16.1578C23.605 13.7261 24.0665 14.0984 21.5305 14.099L14.2825 14.0996C13.5735 14.0996 12.8473 14.0583 12.1404 14.0996Z"
				stroke="currentColor"
				stroke-width="0.5"
			/>
			<path
				d="M21.8306 17.0426C20.6757 17.1519 22.1826 15.0645 22.3828 14.9134C22.5646 14.9429 22.7424 15.0714 22.8729 15.1965C22.7745 15.4871 22.0478 16.8941 21.8306 17.0426Z"
				stroke="currentColor"
				stroke-width="0.5"
			/>
			<path
				d="M14.0468 17.1093C13.8202 17.1095 13.5146 16.9917 13.602 16.7021C13.671 16.4733 14.4227 15.1234 14.6815 14.9872C15.7972 14.872 14.1719 17.062 14.0468 17.1093Z"
				stroke="currentColor"
				stroke-width="0.5"
			/>
			<path
				d="M17.2094 16.6984C17.3464 16.3557 17.9551 15.1456 18.2889 14.9872C19.0924 14.9615 18.3868 15.9223 18.2302 16.2082C18.0814 16.4799 17.9772 16.9408 17.6495 17.0426C17.394 17.0442 17.3048 16.9289 17.2094 16.6984Z"
				stroke="currentColor"
				stroke-width="0.5"
			/>
			<path
				d="M18.3758 20.0083C18.1692 20.2867 17.949 20.6256 17.6491 20.8087C16.824 21.3124 15.9731 20.5011 16.2321 19.6355C16.3284 19.3136 16.6167 18.7072 16.9179 18.5438C18.1194 18.4982 16.1429 20.4783 17.1415 20.2564C17.6346 20.1469 17.821 19.3563 18.1625 19.2954C18.6788 19.2035 18.6098 19.6815 18.3758 20.0083Z"
				stroke="currentColor"
				stroke-width="0.5"
			/>
			<path
				d="M22.6936 19.8339C22.4447 20.1953 22.1649 20.6037 21.7651 20.8098C21.2658 21.0671 20.6809 20.9383 20.4222 20.417C20.1656 19.8998 20.571 18.8691 21.0743 18.6313C21.2563 18.5939 21.4602 18.6693 21.5224 18.8598C21.5736 19.0167 20.4472 20.4173 21.3111 20.2387C21.8316 20.131 22.5888 18.3901 22.8729 19.4792C22.8365 19.6146 22.7709 19.7192 22.6936 19.8339Z"
				stroke="currentColor"
				stroke-width="0.5"
			/>
			<path
				d="M14.0468 19.8988C13.756 20.2779 13.4169 20.7896 12.9299 20.9193C11.4128 21.3237 11.533 18.8669 12.4815 18.6313C13.5659 18.6947 11.8056 20.04 12.6057 20.2843C13.2596 20.1182 13.4748 19.2488 13.8564 19.1599C14.3166 19.0527 14.3021 19.555 14.0468 19.8988Z"
				stroke="currentColor"
				stroke-width="0.5"
			/>
			<path
				d="M17.8953 27.8341C15.2333 28.0028 14.9446 24.2534 17.4476 23.9321C20.038 23.7493 20.4107 27.4523 17.8953 27.8341ZM17.5755 24.5986C15.8412 24.7781 16.0941 27.2759 17.7591 27.1615C19.464 26.9909 19.2276 24.5359 17.5755 24.5986Z"
				stroke="currentColor"
				stroke-width="0.5"
			/>
		</svg>
	);
};

export default CasinoMachine;
