import {
	getDailyRewards,
	getMeClient,
	getPaymentCoins,
	getQuests,
} from "@/lib/actions";
import { useCurrentLocale, useI18n } from "@/locales/client";

import Task from "../../components/earn/Task";
import DailySheet from "../../components/earn/DailySheet";
import { useQuery } from "@tanstack/react-query";
import ScreenLoader from "@/components/ui/ScreenLoader";
import { createFileRoute } from "@tanstack/react-router";
import StarsTask from "@/src/components/earn/StarsTask";

function chunkByIndex<T>(arr: T[], index: number) {
	return [arr.slice(0, index), arr.slice(index, arr.length)];
}

const EarnPage = () => {
	const t = useI18n("earn");

	const locale = useCurrentLocale();

	const { data: daily_rewards } = useQuery({
		queryKey: ["daily-rewards"],
		queryFn: async () => await getDailyRewards(),
	});

	const { data: quests } = useQuery({
		queryKey: ["quests"],
		queryFn: async () => await getQuests(locale),
	});

	const { data: user } = useQuery({
		queryKey: ["user"],
		queryFn: async () => await getMeClient(),
	});

	const { data: paymentCoins } = useQuery({
		queryKey: ["payment-coins"],
		queryFn: async () => await getPaymentCoins(),
	});

	if (!t || !daily_rewards || !quests || !user || !paymentCoins) {
		return <ScreenLoader />;
	}

	const [questsFirstChunk, questsSecondChunk] = chunkByIndex(quests, 2);

	return (
		<main className="h-screen flex flex-col p-6 gap-[42px]">
			<div className="flex flex-col items-center gap-4">
				<h1 className="text-2xl text-center font-semibold transition ease-in-out animate-in duration-300 slide-in-from-left-10 fade-in-0">
					{t("daily")}
				</h1>

				<DailySheet
					daily_rewards={daily_rewards}
					lastClaimDate={user.daily_last_dt}
				/>
			</div>

			<div className="flex flex-col items-center gap-4">
				<h1 className="text-2xl text-center font-semibold  transition ease-in-out animate-in duration-300 slide-in-from-right-10 fade-in-0">
					{t("weekly")}
				</h1>

				<ul className="flex flex-col gap-4 pb-6 overflow-y-auto self-stretch max-h-[55vh] remove-scrollbar">
					{questsFirstChunk.map((quest, index) => (
						<Task key={index} quest={quest} />
					))}
					<StarsTask
						minStars={paymentCoins[0].amount}
						minCoins={paymentCoins[0].coin}
					/>
					{questsSecondChunk.map((quest, index) => (
						<Task key={index} quest={quest} />
					))}
				</ul>
			</div>
		</main>
	);
};

export const Route = createFileRoute("/earn/")({
	component: EarnPage,
});
