import { createRootRoute, Navigate, Outlet } from "@tanstack/react-router";

import JotaiProvider from "@/providers/JotaiProvider";
import QueryProvider from "@/providers/QueryProvider";
import ToastProvider from "@/providers/ToastProvider";
import ScrollProvider from "@/providers/ScrollProvider";
import TelegramLoader from "@/providers/TelegramLoader";
import LocaleProvider from "@/providers/LocaleProvider";
import PlatformProvider from "@/providers/PlatformProvider";

import AppNavbar from "@/components/navbar/AppNavbar";
import EnergyProvider from "@/providers/EnergyProvider";

export const Route = createRootRoute({
	component: () => (
		<div>
			<LocaleProvider>
				<PlatformProvider />

				<ToastProvider />

				<QueryProvider>
					<JotaiProvider>
						<TelegramLoader>
							<Outlet />

							<AppNavbar />
							<EnergyProvider />
							<ScrollProvider />
						</TelegramLoader>
					</JotaiProvider>
				</QueryProvider>
			</LocaleProvider>
			{/* <TanStackRouterDevtools /> */}
		</div>
	),
	notFoundComponent: () => {
		return <Navigate to="/" />;
	},
});
