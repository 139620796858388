import { cn } from "@/lib/utils";
import { getMeClient } from "@/lib/actions";
import { useCurrentLocale, useI18n } from "@/locales/client";

import WheelClient from "../../../components/wheel/client";
import { createFileRoute } from "@tanstack/react-router";
import { useQuery } from "@tanstack/react-query";

const WheelPage = () => {
	const t = useI18n("wheel");

	const locale = useCurrentLocale();

	const { data: user } = useQuery({
		queryKey: ["user"],
		queryFn: async () => await getMeClient(),
	});

	if (!user) {
		return <main>NO USER</main>;
	}

	return (
		<main className="h-screen pb-[128px]">
			<div className="flex flex-col items-center justify-between p-6 pb-0 self-stretch h-full [background:radial-gradient(81.54%_50%_at_50%_50%,#0B1C9D_41.37%,#000000_100%)]">
				<h1
					className={cn(
						"text-[28px] text-center font-semibold leading-8 transition ease-in-out animate-in duration-300 slide-in-from-left-10 fade-in-0",
						locale === "en" && "max-w-[250px]",
					)}
				>
					{t("spinTo")}
				</h1>

				<WheelClient
					lastWheelSpin={user!.last_wheel_of_fortune_dt ?? Date.now()}
				/>
			</div>
		</main>
	);
};

export const Route = createFileRoute("/mine/wheel/")({
	component: WheelPage,
});
