import React, { ReactNode } from "react";
import Slider from "react-slick";

const BannerCarousel = ({ banners }: { banners: ReactNode[] }) => {
	var settings = {
		autoplay: true,
		autoplaySpeed: 2000,
		cssEase: "cubic-bezier(0.47, 0, 0.745, 0.715)",
		dots: true,
		infinite: true,
		speed: 750,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		centerMode: true,
		centerPadding: "20px",
	};
	return (
		<Slider {...settings}>
			{banners.map((banner) => (
				<div className="px-2">{banner}</div>
			))}
		</Slider>
	);
};

export default BannerCarousel;
