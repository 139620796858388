import { IconProps } from "./types";

const MoneyDown: React.FC<IconProps> = (props) => {
	return (
		<svg
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M17.4694 28.519L15.4301 28.518C13.935 28.5172 13.6974 28.5845 13.6994 26.8332L13.7004 18.3877C13.3069 18.3717 11.4812 18.4567 11.2306 18.321C11.0299 18.0611 12.0238 17.1685 12.239 16.9273L13.7595 15.2208C14.2381 14.692 14.7269 14.1715 15.1926 13.631C15.4154 13.3849 15.641 13.0429 15.913 12.8574C16.2508 13.0359 16.8378 13.7911 17.1038 14.0862L19.703 16.9708C20.0036 17.2987 20.3133 17.6222 20.6001 17.9624C21.1021 18.5577 20.0795 18.3888 19.7829 18.3884L18.2063 18.3877L18.2073 26.656C18.2078 27.3775 18.3787 28.2676 17.4694 28.519Z"
				fill="currentColor"
			/>
			<path
				d="M13.607 14.7008C12.5072 14.7148 11.4051 14.7014 10.3051 14.7015L9.37132 14.7016C8.96728 14.7015 8.65598 14.7398 8.33673 14.4498C7.76793 13.9331 7.25018 13.2685 6.72128 12.7036L5.00578 10.8638C4.63408 10.4635 4.20042 10.054 3.90472 9.59301C3.74477 9.34361 3.77633 7.73421 3.82853 7.52471C3.95988 6.99731 4.67728 7.13236 5.07078 7.13231L6.44917 7.13266L26.8411 7.13216C27.2612 7.13096 27.9783 6.99331 28.0815 7.56671C28.1345 7.86136 28.1338 9.16606 28.0721 9.43751C27.9835 9.82796 27.325 10.4166 27.0546 10.7109L24.3933 13.5939C24.0142 14.0076 23.6312 14.6065 23.0368 14.7008C22.5129 14.7119 18.395 14.7275 18.2542 14.657C17.5987 14.3286 16.5499 12.5458 15.9129 12.2427C15.6556 12.4005 15.3468 12.8342 15.1367 13.0692C14.6737 13.5867 14.1171 14.2303 13.607 14.7008ZM14.2198 12.7999C13.761 12.364 12.9175 12.5053 12.6983 11.7951C12.9836 10.4678 17.0803 10.794 17.9841 11.0009C18.3823 11.0921 19.3312 11.2968 19.1694 11.8894C19.0081 12.4804 17.8559 12.4803 17.6255 12.7517C17.7389 12.9323 20.7155 12.8615 21.0902 12.8574C21.4619 12.7874 21.1702 11.7726 23.1294 11.9093C23.3849 11.6125 24.0267 10.7202 24.2088 10.4014C24.1702 10.3503 24.1321 10.2998 24.0985 10.2452C23.8257 10.1476 22.9163 10.2123 22.584 10.2131L8.76468 10.2108C8.46978 10.211 8.16542 10.2042 7.87277 10.2452C7.82923 10.2967 7.78528 10.3479 7.74403 10.4014C7.93653 10.7932 8.26962 11.0928 8.49362 11.4654C8.59247 11.6104 8.68353 11.7597 8.77503 11.9093L9.33943 11.9126C10.1396 11.9252 10.3765 12.1608 10.6897 12.8574C11.4769 12.8775 13.5478 12.9414 14.2198 12.7999Z"
				fill="currentColor"
			/>
		</svg>
	);
};

export default MoneyDown;
