import toast from "react-hot-toast";
import { ButtonHTMLAttributes } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { buyOffering } from "@/lib/actions";
import useTelegram from "@/hooks/useTelegram";
import { cn, numberFormat } from "@/lib/utils";

import {
	Sheet,
	SheetClose,
	SheetContent,
	SheetTrigger,
} from "@/components/ui/sheet";
import Coin from "@/components/ui/Coin";
import Button from "@/components/ui/Button";

import { OfferingType, UserType } from "@/types";
import { useTranslation } from "react-i18next";

interface CardProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	offering: OfferingType;
	cardType?: "default" | "special";
	big?: boolean;
}

const Card: React.FC<CardProps> = ({ offering, cardType = "default", big }) => {
	const { t } = useTranslation();

	const queryClient = useQueryClient();

	const telegram = useTelegram();

	const { mutateAsync, isPending } = useMutation({
		mutationKey: ["card"],
		mutationFn: buyOffering,
		onMutate: async () => {
			telegram?.HapticFeedback.impactOccurred("rigid");

			toast.success("Bought");

			await queryClient.cancelQueries({
				queryKey: ["offerings"],
			});

			const previousFuelCells = await queryClient.getQueryData([
				"offerings",
				{ type: offering.type },
			]);

			const previousUser = await queryClient.getQueryData(["user"]);

			await queryClient.setQueryData(
				["offerings", { type: offering.type }],
				(old: OfferingType[]) => {
					return old.map((o) => {
						if (o.id === offering.id) {
							return {
								...o,
								cost: offering.next_cost,
								profit_per_hour: offering.next_profit_per_hour,
							};
						} else {
							return o;
						}
					});
				},
			);

			await queryClient.setQueryData(["user"], (oldUser: UserType) => ({
				...oldUser,
				coins: oldUser.coins - offering.cost,
				passive_profit: oldUser.passive_profit
					? Math.floor(
							oldUser.passive_profit +
								(offering.next_profit_per_hour -
									Number(offering.profit_per_hour)),
					  )
					: offering.next_profit_per_hour,
			}));

			return { previousFuelCells, previousUser };
		},
		onError: async (err, _, context) => {
			console.log(err);

			telegram?.HapticFeedback.notificationOccurred("error");

			toast.error("Lack of funds");

			await queryClient.setQueryData(["offerings"], context?.previousFuelCells);

			await queryClient.setQueryData(["user"], context?.previousUser);
		},
		onSettled: async () => {
			await queryClient.invalidateQueries({
				queryKey: ["offerings", { type: offering.type }],
			});

			await queryClient.invalidateQueries({ queryKey: ["user"] });
		},
	});

	const onBuy = async () => {
		const user = (await queryClient.getQueryData(["user"])) as UserType;

		if (user.coins >= offering.cost) {
			await mutateAsync(offering.id);
		} else {
			telegram?.HapticFeedback.notificationOccurred("error");

			toast.error("Lack of funds");
		}
	};

	return (
		<Sheet>
			<SheetTrigger asChild>
				<button
					disabled={isPending}
					className={cn(
						"flex flex-col justify-end items-center py-[11px] rounded-lg border border-gray-300 relative overflow-hidden",
						cardType === "default" && "bg-gradient-to-b from-gray-500 to-black",
						cardType === "special" && "h-60",
						big && "col-span-2",
					)}
				>
					{cardType === "special" && (
						<>
							<img
								src={
									offering.image
										? `${import.meta.env.VITE_PUBLIC_BASE_URL}${offering.image}`
										: "/btc.png"
								}
								alt="img"
								className="absolute object-cover z-0"
							/>
							<div
								className={cn(
									"w-full h-full [background:linear-gradient(180deg,rgba(0,0,0,0)_13.79%,#000000_74.33%)] absolute top-0 left-0",
									big &&
										"[background:linear-gradient(180deg,rgba(0,0,0,0)_20%,#000000_100%)]",
								)}
							/>
						</>
					)}

					<div
						className={cn(
							"flex flex-col items-center gap-2 px-3 pb-[7px] border-b border-gray-300 self-stretch relative z-10",
							big && "items-start",
						)}
					>
						{cardType === "default" && (
							<img
								src={
									offering.image
										? `${import.meta.env.VITE_PUBLIC_BASE_URL}${offering.image}`
										: "/btc.png"
								}
								alt="img"
								width={360}
								height={360}
								className={cn("w-16 h-16", !offering.active && "opacity-30")}
							/>
						)}

						<div
							className={cn(
								"flex flex-col items-center gap-1",
								big && "items-start",
							)}
						>
							<p className="text-[13px] font-medium leading-[18px]">
								{offering.name}
							</p>

							<div className="flex items-center gap-2 text-xs">
								<p className="text-gray-100">{t("mine.perHour")}</p>

								<div className="flex items-center gap-1">
									<Coin />

									<p>{`+${Number(offering.profit_per_hour).toFixed(2)}`}</p>
								</div>
							</div>
						</div>
					</div>

					<div
						className={cn(
							"flex items-center justify-center gap-4 pt-2 px-3 text-xs self-stretch relative z-10",
							big && "justify-start",
						)}
					>
						{offering.level !== null && (
							<p>
								<span className="text-gray-100">lvl</span>&nbsp;
								{offering.level}
							</p>
						)}

						<div className="flex items-center gap-1">
							<Coin /> <p>{numberFormat(offering.cost)}</p>
						</div>
					</div>
				</button>
			</SheetTrigger>

			<SheetContent
				side="bottom"
				className="flex flex-col items-center p-6 pb-12 gap-10"
			>
				<img
					src={
						offering.image
							? `${import.meta.env.VITE_PUBLIC_BASE_URL}${offering.image}`
							: "/btc.png"
					}
					alt="img"
					width={360}
					height={360}
					className={cn("w-20 h-20", !offering.active && "opacity-30")}
				/>

				<div className="flex flex-col items-center gap-4">
					<div className="flex items-center gap-1">
						<Coin />

						{offering.level > 0 ? (
							<p className="text-xs">{`+${
								offering.next_profit_per_hour - Number(offering.profit_per_hour)
							} ${t("mine.perHour")}`}</p>
						) : (
							<p className="text-xs">{`+${offering.profit_per_hour} ${t(
								"mine.perHour",
							)}`}</p>
						)}
					</div>

					<div className="flex items-center gap-2">
						<Coin className="w-8 h-8" />

						<p className="text-lg font-semibold leading-6">{offering.cost}</p>
					</div>

					<p className="text-2xl text-center font-semibold leading-8">
						{offering.name}
					</p>

					<p className="text-center">{offering.description}</p>
				</div>

				<SheetClose asChild>
					<Button onClick={onBuy} disabled={isPending} className="self-stretch">
						{t("mine.buy")}
					</Button>
				</SheetClose>
			</SheetContent>
		</Sheet>
	);
};

export default Card;
