import { IconProps } from "./types";

const ExchangeIcon: React.FC<IconProps> = (props) => {
	return (
		<svg
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M17.8 4V20M17.8 20L13.8 16M17.8 20L21.8 16M7.8 20V4M7.8 4L3.8 8M7.8 4L11.8 8"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default ExchangeIcon;
