import { Toaster } from "react-hot-toast";

const ToastProvider = () => {
	return (
		<Toaster
			toastOptions={{ style: { color: "#FFFFFF", background: "#071160" } }}
		/>
	);
};

export default ToastProvider;
