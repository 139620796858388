import { IconProps } from "./types";

const IconoirWalletSolid: React.FC<IconProps> = (props) => {
	return (
		<svg
			width="24px"
			height="24px"
			viewBox="0 0 24 24"
			strokeWidth="1.5"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5 6.25C3.48122 6.25 2.25 7.48122 2.25 9V18C2.25 19.5188 3.48122 20.75 5 20.75H19C20.5188 20.75 21.75 19.5188 21.75 18V9C21.75 7.48122 20.5188 6.25 19 6.25H5ZM16.5 12.25C15.8097 12.25 15.25 12.8097 15.25 13.5C15.25 14.1903 15.8097 14.75 16.5 14.75C17.1903 14.75 17.75 14.1903 17.75 13.5C17.75 12.8097 17.1903 12.25 16.5 12.25Z"
				fill="currentColor"
			></path>
			<path
				d="M16.4847 3.06862C17.7544 2.73004 19 3.68703 19 5.00109H9L16.4847 3.06862Z"
				fill="currentColor"
			></path>
		</svg>
	);
};

export default IconoirWalletSolid;
