import { numberFormat } from "@/lib/utils";

import Coin from "@/components/ui/Coin";
import { Sheet, SheetContent } from "@/components/ui/sheet";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getPaymentCoins, paymentStars } from "@/lib/actions";
import RangeSlider from "@/components/ui/RangeSlider";
import Balancer from "react-wrap-balancer";
import { useEffect, useState } from "react";
import useTelegram from "@/hooks/useTelegram";

interface BuyCoinsSheetProps {
	open: boolean;
	setOpen: (open: boolean) => void;
}

const BuyCoinsSheet: React.FC<BuyCoinsSheetProps> = ({ open, setOpen }) => {
	const { t } = useTranslation();

	const { data: paymentCoins } = useQuery({
		queryKey: ["payment-coins"],
		queryFn: async () => await getPaymentCoins(),
	});

	const [order, setOrder] = useState("4");

	const currentOrder = paymentCoins?.find((v) => v.level === Number(order));

	const telegram = useTelegram();
	const queryClient = useQueryClient();

	useEffect(() => {
		telegram?.HapticFeedback.impactOccurred("light");
	}, [order]);

	const handleCoinsBuy = async () => {
		telegram?.HapticFeedback.impactOccurred("medium");
		const response = await paymentStars({
			type: "quest",
			order: Number(order),
			title: `${numberFormat(currentOrder?.coin)} Ocean Coins`,
			description: t("stars.support"),
		});
		telegram?.openTelegramLink(response.invoiceUrl);

		await queryClient.invalidateQueries({ queryKey: ["user"] });
	};

	return (
		<Sheet open={open} onOpenChange={setOpen}>
			<SheetContent
				side="bottomGold"
				className="flex flex-col  text-black items-center pt-[6vh] px-2 w-full pb-8 data-[state=open]:duration-[0s]"
			>
				<img
					src="/star_bg.png"
					alt=""
					className="absolute right-4 bottom-12 z-0 scale-[2] opacity-30 brightness-200"
				/>
				<div className="text-black text-center relative z-10 w-full px-8">
					<p className="font-medium text-2xl">{t("stars.buy")}</p>
					<p className="text-center mt-4 font-light text-base">
						<Balancer>{t("stars.select")}</Balancer>
					</p>
					<div className="my-8">
						<RangeSlider
							value={order}
							onValueChange={setOrder}
							item={currentOrder}
						/>
					</div>
					<div className="mt-4">
						<div className="flex items-center justify-center">
							<div className="flex items-center space-x-1">
								<span>{numberFormat(currentOrder?.coin)}</span>
								<Coin className="w-6 h-6" />
							</div>
							<span className="px-2">=</span>
							<div className="flex items-center space-x-1">
								<span>
									{Intl.NumberFormat("ru").format(currentOrder?.amount ?? 1)}
								</span>
								<img src="/star.png" alt="" className="w-4 h-4" />
							</div>
						</div>
					</div>
					<button
						onClick={handleCoinsBuy}
						className="w-full mt-8 items-center justify-center px-6 py-2 bg-white rounded-full"
					>
						{t("stars.buyShort")}
					</button>
				</div>
			</SheetContent>
		</Sheet>
	);
};

export default BuyCoinsSheet;
