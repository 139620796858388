import { IconProps } from "./types";

const StarIcon: React.FC<IconProps> = (props) => {
	return (
		<svg
			width="40"
			height="40"
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M19.483 0.456299C17.2667 0.456299 15.0187 11.3711 13.5187 12.4563C11.7006 13.7717 1.22151 13.2347 0.499807 13.9563C0 14.4562 0 14.9562 0 15.4561V15.4563C0 17.1928 6.93001 21.6677 7.99981 21.9563C11.7064 22.9563 20.9999 17.549 22.0186 19.9563C23.0373 22.3636 11.3066 24.5898 9.49981 27.9563C7.96397 30.818 6.10308 36.3271 6.5 38.4563C6.63885 39.2011 7.45542 39.4385 7.5 39.4563C10 40.4563 16.7127 32.5424 19.483 32.4905C22.2534 32.4386 30.0931 40.9205 32.2269 39.2345C34.3606 37.5485 29.0024 28.5565 30.0105 25.1844C30.9202 22.1414 40.0932 18.3272 39.4299 15.0684C38.9999 12.9563 30.0105 15.3856 26.0187 12.9563C23.2483 11.2703 21.6994 0.456299 19.483 0.456299Z"
				fill="white"
			/>
			<path
				d="M14 13.4563C16.4931 11.4918 18 1.4563 19.5 1.4563C21 1.4563 23.9711 11.4086 26.0188 13.4563C27.5188 14.9563 38.75 13.4563 39 14.9563C39.25 16.4563 31 21.9563 29.4932 24.5396C27.9397 27.203 33.5 37.1178 31.9705 38.2914C30.441 39.465 22.0118 31.411 19.5188 31.4563"
				stroke="white"
				strokeWidth="2"
			/>
		</svg>
	);
};

export default StarIcon;
