import { ButtonHTMLAttributes } from "react";

import { cn } from "@/lib/utils";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {}

const Button: React.FC<ButtonProps> = ({ className, ...props }) => {
	return (
		<button
			className={cn(
				"inline-flex items-center justify-center py-3 bg-blue-300 rounded-full self-stretch button-1 disabled:opacity-60",
				className,
			)}
			{...props}
		/>
	);
};

export default Button;
