import { useAtom } from "jotai";

import { profitAtom } from "@/lib/atoms";
import { numberFormat } from "@/lib/utils";

import Coin from "@/components/ui/Coin";
import Button from "@/components/ui/Button";
import { Sheet, SheetClose, SheetContent } from "@/components/ui/sheet";
import { useTranslation } from "react-i18next";

interface PassiveProfitProps {
	prevProfit: number;
	show: boolean;
}

const PassiveProfit: React.FC<PassiveProfitProps> = ({ prevProfit, show }) => {
	const { t } = useTranslation();

	const [open, setOpen] = useAtom(profitAtom);

	if (!(prevProfit && show)) {
		return null;
	}

	return (
		<Sheet open={open} onOpenChange={setOpen}>
			<SheetContent
				side="bottom"
				className="flex flex-col items-center pt-[6vh] px-6 pb-[10vh] data-[state=open]:duration-[0s]"
			>
				<h1 className="text-2xl">{t("exchange.coinsEarned")}</h1>

				<div className="flex items-center gap-2">
					<Coin className="w-14 h-14" />

					<p className="text-5xl font-bold">{numberFormat(prevProfit)}</p>
				</div>

				<p className="text-center">{t("exchange.passiveIncome")}</p>

				<SheetClose asChild>
					<Button className="mt-4">{t("exchange.claimCoins")}</Button>
				</SheetClose>
			</SheetContent>
		</Sheet>
	);
};

export default PassiveProfit;
