import { useQuery } from "@tanstack/react-query";

import { getMe } from "@/lib/actions";

import Coin from "@/components/ui/Coin";

import { UserType } from "@/types";
import { useTranslation } from "react-i18next";
import { useCurrentLocale } from "@/locales/client";

interface TitleProps {
	initialData: UserType;
}

const Title: React.FC<TitleProps> = ({ initialData }) => {
	const { t } = useTranslation();

	const { data: user } = useQuery({
		queryKey: ["user"],
		queryFn: async () => await getMe(),
		initialData,
	});

	return (
		<div className="flex flex-col items-center gap-3">
			<div className="flex items-center gap-2 transition ease-in-out animate-in duration-300 slide-in-from-left-10 fade-in-0">
				<Coin className="w-8 h-8" />

				<h1 className="text-[28px] font-semibold leading-8">
					{user?.coins.toLocaleString("en-US").replaceAll(",", " ")}
				</h1>
			</div>

			<p className="text-[13px] font-medium leading-[18px] text-center transition ease-in-out animate-in duration-300 slide-in-from-right-10 fade-in-0">
				{`${
					user?.passive_profit
						? user.passive_profit.toLocaleString("en-US").replaceAll(",", " ")
						: 0
				} ${t("mine.perHour")}`}
			</p>
		</div>
	);
};

export default Title;
