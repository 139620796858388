const PROFIT_MODAL_KEY = 'profit_modal';

const modalWasShown = () => {
  return !!window.sessionStorage.getItem(PROFIT_MODAL_KEY);
};

const setIsModalShown = () => {
  return window.sessionStorage.setItem(PROFIT_MODAL_KEY, '1');
};

export const ProfitModalService = {
  modalWasShown,
  setIsModalShown,
};
