import useTelegram from "@/hooks/useTelegram";

import LoaderIcon from "@/components/icons/LoaderIcon";

const TelegramLoader = ({ children }: { children: React.ReactNode }) => {
	const telegram = useTelegram();

	if (telegram !== null && telegram && telegram.initDataUnsafe) {
		if (
			import.meta.env.VITE_PUBLIC_DEBUG === "true" ||
			telegram.initDataUnsafe.user?.id === 299563852
		) {
			import("@/lib/eruda").then(({ default: eruda }) => {
				// @ts-expect-error: no declaration for monitor
				import("eruda-monitor").then(({ default: erudaMonitor }) => {
					eruda.default.add(erudaMonitor);
				});
			});
		}

		if (!telegram.isExpanded) {
			telegram.expand();
		}

		if (!telegram.isClosingConfirmationEnabled) {
			telegram.enableClosingConfirmation();
		}

		if (telegram.isVerticalSwipesEnabled) {
			telegram.disableVerticalSwipes();
		}

		if (telegram.headerColor !== "#01011A") {
			telegram.setHeaderColor("#01011A");
		}

		return <>{children}</>;
	}

	return (
		<div className="fixed top-0 left-0 z-[70] w-screen h-screen bg-black flex items-center justify-center">
			<LoaderIcon className="w-6 h-6 animate-spin text-accent" />
		</div>
	);
};

export default TelegramLoader;
