import { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import useTelegram from "@/hooks/useTelegram";
import { getLeaderboard, getMeClient, paymentStars } from "@/lib/actions";
import { useI18n } from "@/locales/client";

import Coin from "@/components/ui/Coin";
import ChevronLeft from "@/components/icons/ChevronLeft";
import ChevronRight from "@/components/icons/ChevronRight";

import { LevelType } from "@/types";

import { characters } from "@/constants";
import { cn } from "@/lib/utils";
import { useTranslation } from "react-i18next";
import { LoaderIcon } from "react-hot-toast";
import { useNavigate, useSearch } from "@tanstack/react-router";
import { Route } from "@/src/routes/league";

interface LeagueClientProps {
	initLevel: LevelType;
}

const LeagueClient: React.FC<LeagueClientProps> = ({ initLevel }) => {
	const { t: tx } = useTranslation();
	const t = useI18n("league");

	const navigate = useNavigate({ from: Route.fullPath });
	const search: { refresh: string } = useSearch({ from: "/league/" });
	console.log({ search });

	// get user if we refreshed page
	const { data: userData } = useQuery({
		queryKey: ["user"],
		queryFn: async () => await getMeClient(),
		enabled: !!search.refresh,
	});

	const [level, setLevel] = useState<LevelType>(initLevel);

	const { data } = useQuery({
		queryKey: ["players", { level }],
		queryFn: async () => await getLeaderboard(level),
	});

	// hack because we cant get result of payment
	const [isLoading, setIsLoading] = useState(false);

	const telegram = useTelegram();
	const queryClient = useQueryClient();

	const handleBuyLevel = async () => {
		// if user enabled, than disable it
		navigate({
			search: () => ({
				lvl: level,
			}),
		});

		if (isLoading) {
			const url = new URL(window.location.href);
			url.searchParams.set("lvl", String(level));
			url.searchParams.set("refresh", "1");
			window.location.assign(url);
		}

		telegram?.HapticFeedback.impactOccurred("medium");
		try {
			const response = await paymentStars({
				type: "level",
				order: level,
				title: characters[level].name,
				description: tx("stars.support"),
			});
			telegram?.openTelegramLink(response.invoiceUrl);

			await queryClient.invalidateQueries({ queryKey: ["user"] });
		} finally {
			setIsLoading(true);
		}
	};

	const canBuyLvl = (userData?.level ?? initLevel) < level;

	return (
		<>
			<div className="flex flex-col items-center gap-2 relative">
				{canBuyLvl && (
					<div className="flex items-center justify-end w-full">
						<div className="absolute top-4 right-0 z-20">
							<button
								onClick={handleBuyLevel}
								className={cn(
									"text-sm rounded-full flex items-center px-6 py-2  border  ",
									isLoading
										? "border-white/40 text-white/40"
										: "text-white border-white",
								)}
							>
								{isLoading && (
									<LoaderIcon className="w-3 h-3 animate-spin mr-2" />
								)}
								<span>{tx("stars.buyShort")}</span>
							</button>
						</div>
					</div>
				)}
				<div className="flex items-center justify-between self-stretch relative">
					<button
						onClick={() => {
							telegram?.HapticFeedback.selectionChanged();
							setLevel((prev) => (prev - 1) as LevelType);
						}}
						disabled={level === 1}
						className="flex items-center justify-start flex-1 disabled:text-gray-200 relative z-10 h-60"
					>
						<ChevronLeft className="w-6 h-6" />
					</button>

					<img
						src={characters[level].image}
						alt="league-img"
						className="w-[57vw] aspect-square absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 z-10"
					/>

					<img
						src={characters[level].blur}
						alt="league-blur"
						className="w-screen h-auto absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 z-0"
					/>

					<button
						onClick={() => {
							telegram?.HapticFeedback.selectionChanged();
							setLevel((prev) => (prev + 1) as LevelType);
						}}
						disabled={level === Object.keys(characters).length}
						className="flex items-center justify-end flex-1 disabled:text-gray-200 relative z-10 h-60"
					>
						<ChevronRight className="w-6 h-6" />
					</button>
				</div>

				<h1 className="text-[28px] font-semibold leading-8">
					{characters[level].name}
				</h1>

				<p className="text-gray-100">{`${t("from")} ${
					characters[level].coins
				}`}</p>
			</div>

			<div className="flex flex-col gap-4">
				<p className="text-lg font-semibold">{t("top")}</p>
				{data?.map((player, index) => (
					<Player
						key={index}
						place={index + 1}
						username={player.username}
						coins={player.coins}
						photo={characters[level].image}
					/>
				))}
			</div>
		</>
	);
};

const Player = ({
	place,
	username,
	coins,
	photo,
}: {
	username: string;
	coins: number;
	photo: string | null;
	place: number;
}) => {
	const [isCoinsCollapsed, setCoinsCollapsed] = useState(true);

	return (
		<div className="flex items-center gap-2">
			<span className="text-gray-200 w-[18px]">{place}</span>

			<div className="w-10 h-10 relative">
				<img
					src={photo ? photo : "/default_user_img.png"}
					alt="avatar"
					className="absolute object-cover scale-95"
				/>
			</div>

			<p className="truncate flex-1">{username}</p>
			<div
				className={cn(
					"flex items-center justify-end gap-2 ml-auto",
					isCoinsCollapsed ? "flex-1" : "flex-auto",
				)}
				onClick={() => setCoinsCollapsed(false)}
			>
				<p
					className={cn(
						"font-semibold",
						isCoinsCollapsed ? "truncate max-w-24" : "",
					)}
				>
					{coins.toLocaleString("en-US")}
				</p>

				<Coin className="w-6 h-6" />
			</div>
		</div>
	);
};

export default LeagueClient;
