import { PaymentCoinsType } from "@/lib/actions";
import React from "react";
import RangeSliderTooltip from "./RangeSliderTooltip";
import { numberFormat } from "@/lib/utils";

interface RangeSliderProps {
	value: string;
	onValueChange: (value: string) => void;
	item?: PaymentCoinsType;
}

const RangeSlider = ({ value, onValueChange, item }: RangeSliderProps) => {
	const correctionOnThumb = [6, 5, 4, 3, 2, 1, 0, -1, -2, -3, -4];

	return (
		<div className="w-full">
			<div
				className="relative w-fit -translate-x-1/2"
				style={{
					// transform: `translate(${((Number(value) - 1) / (10 - 1)) * 100}%, 0)`,
					left: `calc(${((Number(value) - 1) / (10 - 1)) * 100}% + ${
						correctionOnThumb[Number(value)]
					}px)`,
				}}
			>
				<RangeSliderTooltip
					value={numberFormat(item?.coin)}
					discount={item?.discount}
				/>
			</div>
			<input
				type="range"
				min="1"
				max="10"
				value={value}
				step="1"
				onChange={(e) => onValueChange(e.target.value)}
				className="w-full h-2 bg-red-600 rounded-lg appearance-none cursor-pointer dark:bg-gray-700 ocean-track"
				style={{
					background: `linear-gradient(to right, #3443ae 0%, #3443ae ${
						((Number(value) - 1) / (10 - 1)) * 100
					}%, #717bc620 ${
						((Number(value) - 1) / (10 - 1)) * 100
					}%, #717bc620 100%)`,
				}}
			/>
			<div className="text-[10px] font-light justify-between flex items-center">
				{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((lvl) => {
					return (
						<div className="pl-1.5 text-[#161616]">
							<div className="relative pt-1 before:content-[''] before:absolute before:w-[1px] before:h-1 before:bg-[#161616] before:rounded-xl before:left-1/2 before:-translate-x-1/2 before:top-0">
								{lvl}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default RangeSlider;
