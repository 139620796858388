import useTelegram from "@/hooks/useTelegram";
import { useI18n } from "@/locales/client";

import Button from "@/components/ui/Button";

interface InviteButtonProps {
	shareUrl: string;
}

const InviteButton: React.FC<InviteButtonProps> = ({ shareUrl }) => {
	const t = useI18n("friends");

	const telegram = useTelegram();

	return (
		<Button
			onClick={() => telegram?.openTelegramLink(shareUrl)}
			className="w-[calc(100vw-48px)] transition ease-in-out animate-in duration-300 slide-in-from-bottom fade-in-0 fixed bottom-[128px]"
		>
			{t("inviteFriends")}
		</Button>
	);
};

export default InviteButton;
