import ScreenLoader from "@/components/ui/ScreenLoader";
import { useCurrentLocale } from "@/locales/client";
import { LocalStorageService } from "@/services/localStorage.service";
import { createFileRoute } from "@tanstack/react-router";
import { useState } from "react";

const SlotsLobbyAtlantidaSlot = () => {
	const [loading, setLoading] = useState(true);
	const accessToken = LocalStorageService.get("access_token") ?? "";
	const locale = useCurrentLocale();

	return (
		<main className="h-screen flex flex-col">
			<div className="h-full">
				{loading ? <ScreenLoader /> : null}
				<iframe
					src={`/gold/index.html?access=${accessToken}&locale=${locale}`}
					width="100%"
					height="100%"
					className="w-full h-full"
					loading="lazy"
					onLoad={() => setLoading(false)}
				/>
			</div>
		</main>
	);
};

export const Route = createFileRoute("/slots/lobby/gold/")({
	component: SlotsLobbyAtlantidaSlot,
});
